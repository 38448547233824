import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Col, Divider, Drawer, Image, message, Row} from "antd";
import {Radio} from "antd-mobile";
import {CheckCircleOutlined} from "@ant-design/icons";
import EmptyCircle from "../../res/img/emptyCircle.png";
import img1 from "../../res/img/templates/inv1.jpg";
import img2Red from "../../res/img/templates/inv2Red.jpg";
import img2Blue from "../../res/img/templates/inv2Blue.jpg";
import img2Green from "../../res/img/templates/inv2Green.jpg";
import img2Black from "../../res/img/templates/inv2Black.jpg";
import img2Orange from "../../res/img/templates/inv2Orange.jpg";
import img2Voilet from "../../res/img/templates/inv2DarkViolet.jpg";
import img2Yellow from "../../res/img/templates/inv2Yellow.jpg";
import img3Green from "../../res/img/templates/inv3Green.jpg";
import img3Red from "../../res/img/templates/inv3Red.jpg";
import img3Blue from "../../res/img/templates/inv3Blue.jpg";
import img3Orange from "../../res/img/templates/inv3Orange.jpg";
import img3Black from "../../res/img/templates/inv3Black.jpg";
import img3Purple from "../../res/img/templates/inv3Purple.jpg";
import img3Yellow from "../../res/img/templates/inv3Yellow.jpg";

import img4Red from "../../res/img/templates/inv4Red.jpg";
import img4Green from "../../res/img/templates/inv4Green.jpg";
import img4Blue from "../../res/img/templates/inv4Blue.jpg";
import img4Orange from "../../res/img/templates/inv4Orange.jpg";
import img4Black from "../../res/img/templates/inv4Black.jpg";
import img4Voilet from "../../res/img/templates/inv4Voilet.jpg";
import img4Yellow from "../../res/img/templates/inv4yellow.jpg";
import img5Red from "../../res/img/templates/inv5red.jpg";
import img5Green from "../../res/img/templates/inv5Green.jpg";
import img5Blue from "../../res/img/templates/inv5Blue.jpg";
import img5Orange from "../../res/img/templates/inv5Orange.jpg";
import img5Black from "../../res/img/templates/inv5Black.jpg";
import img5Voilet from "../../res/img/templates/inv5Voilet.jpg";
import img5Yellow from "../../res/img/templates/inv5yellow.jpg";
import img6General from "../../res/img/templates/inv6general.png";
import img7General from "../../res/img/templates/inv7general.png";
import img8General from "../../res/img/templates/inv8general.png";
import {useDispatch, useSelector} from "react-redux";
import {loginRequest, saveCompany} from "../../services/api";
import {customDispatcher} from "../../utils";


const imagesMap = {
    "template1": {
        "red": img1,
        "green": img1,
        "#165382": img1,
        "#EE7600":img1,
        "#000000":img1,
        "DarkViolet":img1,
        "DarkGreen":img1,
        "#FFBF00":img1
    },
    "template2": {
        "red": img2Red,
        "green": img2Green,
        "#165382": img2Blue,
        "#000000": img2Black,
        "#EE7600":img2Orange,
        "DarkViolet":img2Voilet,
        "#FFBF00":img2Yellow

    },
    "template3": {
        "red": img3Red,
        "green": img3Green,
        "#165382": img3Blue,
        "#EE7600":img3Orange,
        "#000000":img3Black,
        "DarkViolet":img3Purple,
        "#FFBF00":img3Yellow
    },
    "template4": {
        "red": img4Red,
        "green": img4Green,
        "#165382": img4Blue,
        "#EE7600":img4Orange,
        "#000000":img4Black,
        "DarkViolet":img4Voilet,
        "#FFBF00":img4Yellow
    },
    "template5": {
        "red": img5Red,
        "green": img5Green,
        "#165382": img5Blue,
        "#EE7600":img5Orange,
        "#000000":img5Black,
        "DarkViolet":img5Voilet,
        "#FFBF00":img5Yellow
    },
    "template6": {
        "red": img6General,
        "green": img6General,
        "#165382": img6General,
        "#EE7600":img6General,
        "#000000":img6General,
        "DarkViolet":img6General,
        "#FFBF00":img6General
    },
    "template7": {
        "red": img7General,
        "green": img7General,
        "#165382": img7General,
        "#EE7600":img7General,
        "#000000":img7General,
        "DarkViolet":img7General,
        "#FFBF00":img7General
    },
    "template8": {
        "red": img8General,
        "green": img8General,
        "#165382": img8General,
        "#EE7600":img8General,
        "#000000":img8General,
        "DarkViolet":img8General,
        "#FFBF00":img8General
    }

};


const InvoiceTemplates = ({visible, setVisible}) => {
    const dispatch = customDispatcher(useDispatch());
    const state = useSelector(state => state.oldState);
    const [selectedColor, setSelectedColor] = useState("#165382");
    const [selectedTemplate, setSelectedTemplate] = useState("template2");

    useEffect(() => {
        console.log("## UseTemplate effect ##");
        const _color = _.get(state, 'company.invoiceConfig.color', "#165382");
        const _template = _.get(state, 'company.invoiceConfig.template', "template2");
        setSelectedColor(_color);
        setSelectedTemplate(_template);
        console.log("## Current selection ##", {
            _color, _template
        })
    }, [state]);

    const saveValues = async (obj) => {
        const company = _.cloneDeep(state?.company || {});
        if (!company.invoiceConfig) {
            company.invoiceConfig = {};
        }
        _.assign(company.invoiceConfig, obj);
        await saveCompany(company);
        await loginRequest("", "", dispatch);
        message.success("Invoice Template updated successfully!");
    }

    const tickIcon = (checked) =>
        checked ? <CheckCircleOutlined style={{fontSize: 14, marginBottom: 13}}/> : <div><img alt={"Template Preview"} src={EmptyCircle} style={{width: 14, height: 14, marginBottom: 5}}/></div>;

    const templates = [
        {title: "Classic", value: "template1"},
        {title: "Modern", value: "template2"},
        {title: "Elegant", value: "template3"},
        {title: "Vibrant", value: "template4"},
        {title: "Retro", value: "template5"},
        {title: "(Thermal) Pro", value: "template6"},
        {title: "(Thermal) Swift", value: "template7"},
        {title: "(Thermal) Edge", value: "template8"},
    ];

    return (
        <Drawer
            placement={"top"}
            // size={"large"}
            height={600}
            onClose={() => setVisible(false)}
            visible={visible}
            title={<div>
                <span style={{fontWeight: 'bold', fontSize: 20}}>Invoice Templates</span>
            </div>}
        >
            <Row>
                <Col span={4} style={{fontWeight: 'bold', fontSize: 16, color: 'grey'}}>
                    Color Selection
                </Col>
                <Col span={20}>
                    <Radio.Group
                        onChange={(val) => {
                            setSelectedColor(val);
                            saveValues({color: val});
                        }}
                        value={selectedColor}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            paddingVertical: 6,
                        }}>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: "#165382", '--font-size': 14}} value={"#165382"}>Blue</Radio>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: 'green', '--font-size': 14}} value={"green"}>Green</Radio>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: 'red', '--font-size': 14}} value={"red"}>Red</Radio>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: '#EE7600', '--font-size': 14}} value={"#EE7600"}>Orange</Radio>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: '#000000', '--font-size': 14}} value={"#000000"}>Black</Radio>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: 'DarkViolet', '--font-size': 14}} value={"DarkViolet"}>Voilet</Radio>
                        <Radio icon={tickIcon} className={"colorRadio"} style={{height: 30, background: '#FFBF00', '--font-size': 14}} value={"#FFBF00"}>Yellow</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Divider style={{padding: 0, margin: 8}}/>
            <Row>
                <Col span={4} style={{fontWeight: 'bold', fontSize: 16, color: 'grey'}}>
                    Design Selection
                </Col>
                <Col span={20}>
                    {
                        templates.map(t => <div style={{textAlign: 'center'}} className={"templatePreview"}>
                            <div style={{fontWeight: 'bold', fontSize: 15, color: '#444', marginBottom: 4}}>{t.title}</div>
                            <Image style={{height: 200, border: '0px dashed black'}} src={imagesMap[t.value][selectedColor]}/>
                            {
                                t.value === selectedTemplate ?
                                    <div style={{height: 36, width: '100%', textAlign: 'center', marginTop: 8, color: 'green', fontWeight: 'bold', fontSize: 14}}><CheckCircleOutlined/> Selected</div> :
                                    <div style={{height: 36, width: '100%', textAlign: 'center', marginTop: 8,}}>
                                        <Button onClick={() => {
                                            setSelectedTemplate(t.value);
                                            saveValues({template: t.value});
                                        }} type={"primary"} style={{fontSize: 14, borderRadius: 100}}>Select</Button>
                                    </div>
                            }
                        </div>)
                    }
                </Col>
            </Row>
        </Drawer>

    )
};

export default InvoiceTemplates;
