import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Select} from "antd";
import {getLedgers, saveVoucher} from "../../services/api";
import {useSelector} from "react-redux";
import {convertArrayToMap, sleep} from "../../utils";
import {trackEvent, EMpEventName} from "../../services/analytics/mixpanel";

const {Option} = Select;

const DrawerPaymentWrite = ({closeDrawer, entryType, voucher, onSave = () => {}}) => {
    const state = useSelector(state => state.oldState);
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [ledgers, setLedgers] = useState([]);
    const [selectedLedgerId, setSelectedLedgerId] = useState();

    const ledgersMap = convertArrayToMap(ledgers, "_id");
    const currentBalanceDiv = selectedLedgerId ?
        <div style={{color: 'red'}}>
            (Current Balance: ₹ {ledgersMap[selectedLedgerId]?.balance || 0})
        </div> :
        <></>;


    useEffect(() => {
        if (state?.company) {
            getLedgers("all").then(resp => setLedgers(resp.data.data.ledgers));
        }
    }, [state])

    useEffect(() => {
        form.resetFields();
        if (voucher) {
            form.setFieldsValue({
                ledgerCr: voucher.ledgerCr,
                date: moment(voucher.date),
                paymentMode: voucher.paymentMode || "Cash",
                ledgerDr: voucher.ledgerDr,
                privateNote: voucher.privateNote,
                amount: voucher.amount,
                entryMode: voucher.entryMode || "Expense",
                distribution: voucher.distribution,
            });
            setSelectedLedgerId(voucher.ledgerCr);
        } else {
            form.setFieldsValue({
                date: moment(),
                paymentMode: "Cash",
            });
            setSelectedLedgerId(undefined);
        }
    }, [voucher, form]);


    return (
        <div>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 6, style: {
                        textAlign: 'left',
                    }
                }}
                wrapperCol={{span: 16}}
                onFinish={async (data) => {
                    if (voucher) {
                        data._id = voucher._id;
                        // data.documentNumber = voucher.documentNumber; - docNumber not required to pass for receipt now
                    }
                    data.type = "payment";
                    data.entryType = entryType;
                    if (entryType === "Invoice-Wise") {
                        let totalAmount = 0;
                        data.distribution.forEach(row => {
                            totalAmount += row.amount;
                        });
                        data.amount = totalAmount;
                    } else {
                        data.distribution = [];
                    }
                    setSaving(true);
                    // return;
                    const response = await saveVoucher({
                        voucher: data
                    });
                    onSave();
                    await sleep(500);
                    if (!response.data.success) {
                        message.error(response.data.message);
                        setSaving(false);
                        return;
                    }

                    message.success("Payment record saved");
                    trackEvent(EMpEventName.FEATURE_USED, {
                        featureName: !!voucher ? "Edit Voucher" : "Create Voucher",
                    });
                    setSaving(false);
                    closeDrawer();
                    form.resetFields();
                    form.setFieldsValue({
                        date: moment(),
                        paymentMode: "Cash",
                    })
                }}
                onFinishFailed={(err) => {
                    // console.log("## Finish Failed ##", err);
                }}
                autoComplete="off"
            >
                <Row justify={"center"} align={"middle"}>
                    <Col span={16}>
                        <Form.Item labelCol={{
                            span: 9, style: {
                                textAlign: 'left',
                            }
                        }} wrapperCol={{span: 15}} name={"ledgerDr"} label={"Paid to"}
                                   rules={[{required: true, message: "Please select customer"}]}>
                            <Select
                                // options={}
                                placeholder={entryType === "Expense" ? "Select Ledger" : "Select Vendor"}
                                showSearch={true}
                                filterOption={(input, {searchContent}) =>
                                    searchContent?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
                                    // || label?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={setSelectedLedgerId}
                            >
                                {
                                    // change according to vendor/ expense 
                                    ledgers.filter(l => !l.type || l.type === (entryType === "Expense" ? "expense" : "creditor")).map(l => (
                                        <>
                                            <Option value={l._id} searchContent={l.name}>
                                                <div>
                                                    <b>{l.name}</b>
                                                </div>
                                                <div style={{fontSize: 10, fontStyle: 'italic', color: 'grey'}}>
                                                    Balance: ₹ {l.balance || 0}
                                                </div>
                                            </Option>
                                        </>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{paddingLeft: 16, fontStyle: 'italic', paddingBottom: 16}}>
                        {currentBalanceDiv}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{
                                span: 12, style: {
                                    textAlign: 'left',
                                }
                            }}
                            wrapperCol={{span: 12}}
                            name="date"
                            label="Payment Date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Date"
                                },
                            ]}
                        >
                            <DatePicker format={"DD MMM YYYY"} allowClear={false}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{
                                span: 12, style: {
                                    textAlign: 'left',
                                }
                            }}
                            wrapperCol={{span: 12}}
                            name="paymentMode"
                            label="Payment Mode"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select payment mode"
                                },
                            ]}
                        >
                            <Select
                                options={
                                    ["Cash", "UPI", "Card", "EMI", "Bank Transfer", "Cheque"].map(mode => ({
                                        label: mode, value: mode
                                    }))
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="ledgerCr"
                    label="Paid Account"
                    rules={[{required: true, message: "Please select"}]}
                >
                    <Select
                        placeholder={"Select Paying Account"}
                        options={
                            ledgers.filter(l => l.type === "cash").map(l => ({
                                label: l.name, value: l._id
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item
                    name={"privateNote"}
                    label={"Private Note"}
                >
                    <Input.TextArea rows={2} placeholder={"Private note (not visible on receipt)"}/>
                </Form.Item>

                        <Form.Item
                            name="amount"
                            label="Amount Paid (₹)"
                            rules={[
                                {
                                    required: true,
                                    message: "Invalid Amount"
                                },
                            ]}
                        >
                            <InputNumber type="number" min={1} max={100000000} style={{fontWeight: 'bold', width: '100%'}}/>
                        </Form.Item> 

                {/*<Form.Item wrapperCol={{ offset: 8, span: 16 }}>*/}
                <Button type="primary" htmlType="submit" loading={saving}>
                    Save Payment Record
                </Button>
                {/*</Form.Item>*/}
            </Form>


        </div>
    )
};

export default DrawerPaymentWrite;
