    import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Alert, Button, Form, Input, message} from "antd";
import {useSelector} from "react-redux";
import {emailInvoice} from "../../services/api";
import moment from "moment";
import {voucherTypes} from "../../models/constants";
import {getPublicShareLink, PublicShareLinks} from "../../utils";
import { getemailBodyAndSubjectForLedgerStatement } from "./service";

const DrawerEmail = ({document, closeDrawer}) => {
    const state = useSelector(state => state.oldState);
    const voucher = _.cloneDeep(document);
    if (voucher.type === "receipt") {
        const crParty = voucher.crParty;
        voucher.crParty = voucher.party;
        voucher.party = crParty;
    }

    console.log("## VOucherEmail ##", voucher);
    const [form] = Form.useForm();
    const [sendingMail, setSendingMail] = useState(false);

    const docType = voucherTypes[voucher.type] || {};
    const invoiceNum = docType.idType !== "mixed" ? voucher.documentNumber : `${voucher.documentNumber.prefix}${voucher.documentNumber.num}`;
    let filename = `${docType.filePrefix}-${invoiceNum}-${moment(voucher.date).format('DD-MM-YYYY')}.pdf`;
    let ledgerOptions;
    useEffect(() => {
        // const invoiceNum = `${voucher?.documentNumber?.prefix}${voucher?.documentNumber?.num}`;
        const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
        const formValues = {
            from: "no-reply@khatabuddy.com",
            replyTo: state?.user?.email || "no-reply@khatabuddy.com",
            to: voucher?.party?.email,
            subject: `${docType.name} ${invoiceNum} from ${state?.company?.name}`,
            body: `Hi ${voucher?.party?.name},\nThank you for your business.\nPlease find the ${docType.name} #${invoiceNum} here:\n${publicLink}\n\nRegards,\n${state?.company?.name}`
        };
        if(voucher?.ledger){
            const emailContent = getemailBodyAndSubjectForLedgerStatement(voucher,state?.company?.name)
            console.log("email content is --->>>", emailContent)
            formValues.to = voucher?.email
            formValues.subject = emailContent?.subject
            formValues.body = emailContent?.body      
            filename = `ledgerstatement-${voucher?.from}-to-${voucher?.to}.pdf`
            ledgerOptions = {
                from:voucher?.startDate,
                to:voucher?.endDate
            }

        }
        

        form.resetFields();
        form.setFieldsValue(formValues);
    }, [voucher, form, docType?.name, invoiceNum, state]);

    return (
        <div>
            <Form
                form={form}
                name="basic"
                    labelCol={{
                        span: 5, style: {
                            textAlign: 'left',
                        }
                    }}
                wrapperCol={{span: 18}}
                onFinish={async (data) => {
                    try {
                        console.log("## Finished ##", data);
                        setSendingMail(true);
                        data.voucherId = voucher?._id;
                        data.filename = filename;
                        if(voucher?.ledger){
                            data.ledgerOption = ledgerOptions
                        }
                        const response = await emailInvoice(data);
                        if (response?.data?.success){
                            message.success(`${docType.label || docType.name || "Document"} sent on Email`);
                            closeDrawer();
                        }
                        else{
                            message.error(response?.data?.message || "something went wrong");
                        } 
                    } catch (error) {
                        console.error("## Error ##", error);
                        message.error("Something went wrong, please contact support");
                    } finally {
                        setSendingMail(false);
                    }
                    
                }}
                onFinishFailed={(err) => {
                    console.error("## Send Email Finish Failed ##", err);
                }}
                autoComplete="off"
            >

                <Form.Item
                    name="from"
                    label="From"
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: "Invalid Email"
                        },
                    ]}
                    hidden={true}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    name="replyTo"
                    label="Reply To"
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: "Invalid Email"
                        },
                    ]}
                    hidden={true}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    name="to"
                    label="To"
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: "Invalid Email"
                        },
                    ]}
                >
                    <Input placeholder={"Recipient email address"}/>
                </Form.Item>
                <Form.Item
                    name="cc"
                    label="CC"
                >
                    <Input placeholder={"CC (Use comma separator for multiple emails)"}/>
                </Form.Item>
                <Form.Item
                    name="bcc"
                    label="BCC"
                >
                    <Input placeholder={"BCC (Use comma separator for multiple emails)"}/>
                </Form.Item>
                <Form.Item
                    name="subject"
                    label="Email Subject"
                    rules={[
                        {
                            required: true,
                            message: "can't be blank"
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"body"}
                    label={"Email Body"}
                >
                    <Input.TextArea rows={9}/>
                </Form.Item>

                <Alert type={"info"} showIcon={true}
                       message={"PDF attachment will also be sent along with this email."}/>

                <br/>

                {/*<Form.Item wrapperCol={{ offset: 8, span: 16 }}>*/}
                <Button type="primary" htmlType="submit" loading={sendingMail}>
                    Send Email
                </Button>
                {/*</Form.Item>*/}
            </Form>
        </div>
    )
};

export default DrawerEmail;
