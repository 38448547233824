import { getCart } from "../../services/cookies";

export const getTotalCost = ()=>{
    let cartTotal = 0;
    try{
        const cart = getCart()
        cart.forEach((product)=>{
            cartTotal = cartTotal + Number(product.qty* product.defaultRate);
        })
        return cartTotal
    }catch(e){
        console.error('Error in getting cart total', e);
        return 0
    }
}