import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message, PageHeader,
    Row,
    Select,
    Table, Tooltip
} from "antd";
import _ from "lodash";
import {
    getLedgers,
    saveVoucher,
    getVouchers,
    saveLedger,
    loginRequest,
    getVouchersByLedgerId
} from "../services/api";
import {useDispatch, useSelector} from "react-redux";
import {countriesMap, RUPEE, states, voucherTypes} from "../models/constants";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined, InfoCircleOutlined, PlusCircleFilled,
    PlusOutlined, QuestionCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import {
    assignTableColsWidth,
    beautifyAmount,
    customDispatcher,
    defaultDocumentPrefixes,
    formulateItems
} from "../utils";
import {SwitchSelect} from "../components/switch";
import VoucherAttachments from "../components/VoucherAttachments";
import ItemsComponent from "./voucherPage/ItemsComponent";
import {isBankLedger} from "../utils/ledger.utils";
import { ButtonV2 } from "../components/ButtonV2";
import {EMpEventName, trackEvent} from "../services/analytics/mixpanel";

const {Option} = Select;


const DocumentNumber = ({value, onChange}) => {
    // console.log("## Vals ##", {value, onChange});
    const {prefix, num} = value || {};

    return (
        <Input.Group compact>
            <Input value={prefix} onChange={(e) => onChange({prefix: e.target.value, num})}
                   style={{
                       width: 100,
                       textAlign: 'right',
                       borderTopLeftRadius: 8,
                       borderBottomLeftRadius: 8,
                   }}
                   placeholder={"Prefix"}/>
            <InputNumber min={1} value={num} onChange={(val) => onChange({prefix, num: val})}
                         style={{
                             width: 100,
                             borderTopRightRadius: 8,
                             borderBottomRightRadius: 8
                         }}
                         placeholder={"Number"}/>
        </Input.Group>
    )
}




const fetchLedgers = async ({setLedgersMap}) => {
    console.log("## Fetching Ledgers ##");
    const ledgers = (await getLedgers("all")).data.data.ledgers;
    const _ledgersMap = _.keyBy(ledgers, "_id");
    setLedgersMap(_ledgersMap);
};



const VoucherWrite = ({siderCollapsed}) => {
    const params = useParams();
    const {voucherType, voucherId} = params || {};
    const [searchParams] = useSearchParams();
    const copyFromVid = searchParams.get('copyFromVid');
    console.log("## copyFromVid ##", copyFromVid);
    const navigate = useNavigate();
    const dispatch = customDispatcher(useDispatch());
    const docType = voucherTypes[voucherType] || {};
    const isNewVoucher = !voucherId;
    const state = useSelector(state => state.oldState);
    const [form] = Form.useForm();
    const [formReceipt] = Form.useForm();
    const [extraDiscountType, setExtraDiscountType] = useState('fixed');
    const [extraDiscountValue, setExtraDiscountValue] = useState(0);
    const [voucher, setVoucher] = useState({});
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [checkPaymentReceived, setCheckPaymentReceived] = useState(false);
    const [enableRoundOff, setEnableRoundOff] = useState(true);
    const [isTaxLess, setIsTaxLess] = useState(false);
    const actualReceived = checkPaymentReceived ? receivedAmount : 0;
    const [ledgersMap, setLedgersMap] = useState({});
    const [selectedLedgerId, setSelectedLedgerId] = useState();
    const [voucherItems, setVoucherItems] = useState([]);
    const [flagFetchVoucher, setFlagFetchVoucher] = useState(false);
    const [receiptError, setReceiptError] = useState("");
    const [creditNoteInvoices, setCreditNoteInvoices] = useState([]);

    useEffect(() => {
        if (["creditNote", "debitNote"].includes(voucherType) && selectedLedgerId) {
            getVouchersByLedgerId(selectedLedgerId, voucherType === "creditNote" ? "invoice" : "purchase").then(
                resp => setCreditNoteInvoices(resp.data.data.vouchers)
            )
        }
    }, [selectedLedgerId, voucherType]);

    useEffect(() => {
        let selectedBankAccount = state.company?.invoiceConfig?.defaultBankId;
        if (!selectedBankAccount) {
            selectedBankAccount = Object.values(ledgersMap).filter(isBankLedger)[0]?._id;
        }

        if (selectedBankAccount) {
            form.setFieldsValue({
                bankAccount: selectedBankAccount
            });
        }
    }, [ledgersMap, state]);

    const dbItemsMap = {};
    _.get(state, 'items', []).forEach(item => {
        dbItemsMap[item._id] = item
    });

    const selectedLedger = ledgersMap[selectedLedgerId] || {};
    // console.log("## Selected Ledger ##", selectedLedger);

    const {
        // tableItems,
        summary: {subtotal, roundedTotal, roundedOff, totalTax, extraDiscount}
    } = formulateItems({
        items: voucherItems, dbItemsMap, isIgst: selectedLedger?.state !== _.get(state, 'company.state'),
        extraDiscount: {
            extraDiscountType,
            extraDiscountValue,
        }, isAbroad: selectedLedger?.country && selectedLedger?.country !== "IN",
        isRoundOffEnabled: enableRoundOff,
        isTaxLess,
    });


    useEffect(() => {
        console.log('## Before.......')
        form.setFieldsValue(voucher);
        const {receiptData = {}} = voucher;
        console.log("## FETCHED RCD DATA ##", receiptData);
        formReceipt.setFieldsValue({
            receiver: receiptData.ledgerDr,
            amount: receiptData.amount,
            remarks: receiptData.remarks
        });
        setReceivedAmount(receiptData.amount || 0);
        setCheckPaymentReceived(voucher.paymentReceived);
    }, [voucher, form, formReceipt]);

    useEffect(() => {
        if (state.company && !flagFetchVoucher) {
            fetchLedgers({setLedgersMap});
            setFlagFetchVoucher(true);
        }
    }, [state, form]);

    const autoIncValue = _.get(state, `company.autoIncrement.${voucherType}`, 1);
    const docNumPrefix = _.get(state, `company.invoiceConfig.prefixes.${voucherType}`, defaultDocumentPrefixes[voucherType]);

    useEffect(() => {
        let _voucher = {};
        if (!flagFetchVoucher) {
            return;
        }
        if (true) {
            if (isNewVoucher) { // new voucher mode
                const newVoucherDefaults = {
                    date: moment(),
                    items: [],
                    enableRoundOff: true,
                    isTaxLess: false,
                };
                if (docType.idType === "mixed") { // currently only this will be the case always
                    newVoucherDefaults.documentNumber = {
                        prefix: docNumPrefix,
                        num: autoIncValue
                    };
                } else {
                    newVoucherDefaults.documentNumber = 999; // code will never come here currently
                }
                if (copyFromVid) {
                    (async () => {
                        const resp = await getVouchers("notRequired", copyFromVid);
                        const sourceVoucher = _.get(resp, 'data.data.vouchers[0]');
                        Object.assign(newVoucherDefaults, {
                            items: sourceVoucher.items,
                            ledgerDr: sourceVoucher.ledgerDr,
                            ledgerCr: sourceVoucher.ledgerCr,
                            extraDiscountValue: sourceVoucher.extraDiscountValue,
                            extraDiscountType: sourceVoucher.extraDiscountType,
                            enableRoundOff: !!sourceVoucher?.customConfig?.enableRoundOff,
                            isTaxLess: !!sourceVoucher?.customConfig?.isTaxLess,
                        });
                        setVoucher(newVoucherDefaults);
                        setVoucherItems(sourceVoucher.items);
                        setSelectedLedgerId(sourceVoucher.ledgerDr);
                        setExtraDiscountType(sourceVoucher.extraDiscountType || "fixed");
                        setExtraDiscountValue(sourceVoucher.extraDiscountValue || "0");
                        setEnableRoundOff(!!sourceVoucher?.customConfig?.enableRoundOff);
                        setIsTaxLess(!!sourceVoucher?.customConfig?.isTaxLess);
                    })();
                } else {
                    setVoucher(newVoucherDefaults)    
                }
            } else {    // edit mode
                getVouchers("notRequired", voucherId).then((res) => {
                    _voucher = _.get(res, 'data.data.vouchers[0]');
                    _voucher.date = moment(_voucher.date);
                    _voucher.dueDate = _voucher.dueDate ? moment(_voucher.dueDate) : undefined;
                    if (!_voucher.items) {
                        _voucher.items = [];
                    }
                    _.assign(_voucher, _voucher.customConfig || {});
                    if (_voucher.enableRoundOff === undefined) {
                        _voucher.enableRoundOff = true;
                    }
                    if (_voucher.isTaxLess === undefined) {
                        _voucher.isTaxLess = false;
                    }
                    setVoucher(_voucher);
                    setVoucherItems(_voucher.items);
                    setSelectedLedgerId(_voucher.ledgerDr);
                    setExtraDiscountType(_voucher.extraDiscountType || "fixed");
                    setExtraDiscountValue(_voucher.extraDiscountValue || "0");
                    setEnableRoundOff(_voucher.enableRoundOff);
                    setIsTaxLess(_voucher.isTaxLess);
                })
            }
        }
    }, [flagFetchVoucher, docType.idType, isNewVoucher, autoIncValue, docNumPrefix, voucherId, voucherType]);

    let filterDr, filterCr;
    if (voucherType === "receipt" || voucherType === "invoice") {
        filterDr = ({type}) => type === "cash";
        filterCr = ({type}) => ["creditor", "debtor"].includes(type);
    } else {
        filterCr = ({type}) => type === "cash";
        if (voucherType === "expense") {
            filterDr = ({type}) => type === "expense";
        } else { // payment
            filterDr = ({type}) => type === "creditor";
        }
    }

    const openCustomerDetailsDrawer = (updateMode) => dispatch({
        popData: {
            visible: true,
            width: 550,
            type: "ledger",
            title: updateMode ? "Edit Customer Details" : "Add New Customer",
            data: {
                selectedRecord: updateMode ? selectedLedger : {
                    type: ["purchase", "debitNote"].includes(voucherType) ? "creditor" : "debtor"
                },
                onSave: async (values) => {
                    const isNewLedger = !updateMode;
                    const baseLedger = isNewLedger ? {} : selectedLedger;
                    _.assign(baseLedger, values);
                    try {
                        const resp = await saveLedger(baseLedger);
                        await fetchLedgers({setLedgersMap});
                        if (isNewLedger) {
                            voucher.ledgerDr = _.get(resp, 'data.data.ledger._id');
                            form.setFieldsValue({ledgerDr: voucher.ledgerDr});
                            setSelectedLedgerId(voucher.ledgerDr);
                        }
                        message.success("Customer Saved Successfully");
                    } catch (err) {
                        message.error('Failed to save customer');
                        console.error("Failed to save customer", err);
                    }
                }
            },
            bodyStyle: {
                padding: 16,
            }
        }
    });

    return (
        <>

            <div className={"outletDiv"}
                 style={{padding: 20, paddingTop: 0, paddingBottom: 0, border: '0px solid green'}}>
                <div style={{
                    maxHeight: '100%',
                    // border: '2px solid blue',
                    overflowY: 'scroll'
                }}>
                    <Form
                        hideRequiredMark={true}
                        style={{
                            // fontWeight: 'bold'
                            // height: '100%',
                            // border: '2px solid yellow'
                        }}
                        form={form}
                        layout="horizontal"
                        name="form_in_modal"
                        initialValues={voucher}
                        onValuesChange={(vals) => {
                            console.log("## ParentFormVals ##", vals);
                            if (vals.extraDiscountType) {
                                setExtraDiscountType(vals.extraDiscountType);
                            }
                            if (vals.paymentReceived !== undefined) {
                                setCheckPaymentReceived(vals.paymentReceived);
                            }
                            if (vals.extraDiscountValue !== undefined) {
                                setExtraDiscountValue(vals.extraDiscountValue);
                            }
                            if (vals.enableRoundOff !== undefined) {
                                setEnableRoundOff(vals.enableRoundOff);
                            }
                            if (vals.isTaxLess !== undefined) {
                                setIsTaxLess(vals.isTaxLess);
                            }
                        }}
                    >
                        <Row style={{border: '0px solid red', marginBottom: 8, marginTop: 8}} justify={"center"}
                             align={"middle"}>
                            <Col span={12}>
                                <PageHeader
                                    className="site-page-header"
                                    onBack={() => window.history.back()}
                                    title={isNewVoucher ? `Create ${docType.label || docType.name}` : `Edit ${docType.label || docType.name}`}
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                    }}
                                    // extra={[]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    style={{margin: 0, paddingRight: 16}}
                                    name= {["purchase", "debitNote"].includes(voucherType) ? "externalDocumentNumber" : "documentNumber"}
                                    label={docType.idTitle}
                                    rules={[
                                        {
                                            required: true,
                                            message: "can't be blank",
                                            validator: (rule, value, cb) => {
                                                console.log("## Validator ##", value);
                                                if (["number", "text"].includes(docType.idType)) {
                                                    if (!value) {
                                                        cb("can't be blank");
                                                    }
                                                    cb();
                                                    return;
                                                }
                                                if (!value || !value.num) {
                                                    cb("can't be blank");
                                                } else {
                                                    if (!value.prefix) {
                                                        value.prefix = "";
                                                    }
                                                    cb();
                                                }
                                                // value.length < 3 ? cb("too short") : cb();
                                            }
                                        },
                                    ]}
                                >
                                    {
                                        docType.idType === "mixed" ?
                                            <DocumentNumber/> :
                                            (docType.idType === "text" ?
                                                    <Input placeholder={docType.idTitle}/>
                                                    : <InputNumber placeholder={docType.idTitle} min={1}/>
                                            )
                                    }
                                </Form.Item>

                            </Col>
                            <Col span={6} style={{paddingRight: 16}}>
                                <Form.Item
                                    labelCol={{
                                        span: 8, style: {
                                            textAlign: 'left'
                                        }
                                    }}
                                    style={{margin: 0}} label={`${docType.datePrefix || ""}Date`} name={"date"}
                                    rules={[{required: true, message: "can't be blank"}]}>
                                    <DatePicker format={"DD MMM YYYY"} allowClear={false}/>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row style={{border: '0px solid red'}}>
                            {
                                ["invoice", "proforma", "purchase", "delivery", "creditNote", "debitNote"].includes(voucherType) ?
                                    <Col span={24}>

                                        <Row style={{
                                            background: '#eeeefa',
                                            paddingTop: 16,
                                            paddingBottom: 16,
                                            border: '1px solid lightgrey',
                                            borderRadius: 8,
                                        }}>
                                            <Col span={12}
                                                 style={{border: "0px solid blue", paddingLeft: 16, paddingRight: 16}}>
                                                <Row style={{
                                                    width: '100%',
                                                    border: '0px solid blue',
                                                    padding: 0,
                                                    margin: 0
                                                }}>
                                                    <Col span={22}>
                                                        <Form.Item labelCol={{span: 5, style: {textAlign: 'left'}}}
                                                                   style={{width: '100%'}}
                                                                   wrapperCol={{span: 19}}
                                                                   label={["purchase", "debitNote"].includes(voucherType) ? "Vendor" : "Customer"}
                                                                   name={"ledgerDr"}
                                                                   rules={[{required: true, message: "can't be blank"}]}
                                                                   className={"sidePads"}>
                                                            <Select
                                                                // ref={(ref) => setRefLedger(ref)}
                                                                placeholder={`Select ${["purchase", "debitNote"].includes(voucherType) ? "Vendor" : "Customer"} or Add New`}
                                                                // onSearch={(val) => setNewClientName(val)}
                                                                onChange={(id) => {
                                                                    setSelectedLedgerId(id);
                                                                }}
                                                                style={{width: '100%', padding: 0, margin: 0}}
                                                                filterOption={(input, {ledger}) =>
                                                                    ledger?.name?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                                                    || ledger?.address?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                optionLabelProp="partyName"
                                                                dropdownRender={menu => (
                                                                    <div
                                                                        className={"menuWrapper"}
                                                                        style={{padding: 4}}
                                                                    >
                                                                        {menu}
                                                                        <Divider style={{margin: '4px 0'}}/>
                                                                        <Button
                                                                            // type={"primary"}
                                                                            // className={"customBorder"}
                                                                            className={"btnCustom3"}
                                                                            style={{
                                                                                width: '70%',
                                                                                paddingLeft: 16,
                                                                                marginLeft: '15%',
                                                                                marginTop: 8,
                                                                                marginBottom: 8,
                                                                                background: '#e8eaf6',
                                                                                color: '#283593',
                                                                                fontWeight: 'bold'
                                                                            }} onClick={() => {
                                                                            openCustomerDetailsDrawer(false);
                                                                        }}><PlusCircleFilled/> Add
                                                                            New {voucherType === "purchase" ? "Vendor" : "Client"}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                                showSearch
                                                                allowClear
                                                            >
                                                                {_.map(Object.values(ledgersMap).filter(l =>
                                                                    ["purchase", "debitNote"].includes(voucherType) ?
                                                                        (l.type === "creditor") :
                                                                    (!l.type || l.type === "debtor")
                                                                ), (led) => (
                                                                    <Option style={{
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        height: 'auto',
                                                                        borderBottom: '1px solid #eee'
                                                                    }} key={led._id} value={led._id} ledger={led}
                                                                            partyName={<b>{led.name}</b>}
                                                                    >
                                                                        <div className={"customHover"} style={{
                                                                            padding: 4,
                                                                            paddingLeft: 8,
                                                                            borderRadius: 0,
                                                                            height: 'auto'
                                                                        }}>
                                                                            <strong>{led.name}</strong>
                                                                            <div>
                                                                                {led.address && (
                                                                                    led.address.length > 50 ? (led.address.slice(0, 50) + " ...") :
                                                                                        led.address
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Tooltip
                                                            title={selectedLedgerId ? `Edit ${["purchase", "debitNote"].includes(voucherType) ? "Vendor" : "Customer"} Details` : `Add New ${["purchase", "debitNote"].includes(voucherType) ? "Vendor" : "Customer"}`}>
                                                            <Button className={"btnCustom3"}
                                                                    style={{
                                                                        background: '#b39ddb',
                                                                        color: '#283593',
                                                                        padding: 0,
                                                                        height: 32,
                                                                        width: 32,
                                                                        fontWeight: 'bold',
                                                                        fontSize: 13,
                                                                        paddingLeft: 8,
                                                                        paddingRight: 8,
                                                                        marginTop: 2
                                                                    }}
                                                                    onClick={(e) => {
                                                                        openCustomerDetailsDrawer(!!selectedLedgerId);
                                                                    }}
                                                            >
                                                                {
                                                                    selectedLedgerId ?
                                                                        <EditOutlined style={{color: '#fff'}}/> :
                                                                        <PlusOutlined style={{color: '#fff'}}/>
                                                                }

                                                            </Button>
                                                        </Tooltip>

                                                    </Col>
                                                </Row>
                                                <Row style={{width: '100%'}}>
                                                    <Col offset={5} span={19}>
                                                        {
                                                            selectedLedgerId && <>
                                                                <div style={{color: '#b71c1c'}}>
                                                                    Pending Amount
                                                                    Due: <b>₹ {beautifyAmount(selectedLedger.balance || 0)}</b>
                                                                </div>
                                                                <div>
                                                                    <b>GSTIN:</b> {selectedLedger.gstin ?
                                                                    <span>
                                                                {selectedLedger.gstin}
                                                            </span>
                                                                    :
                                                                    <span style={{color: 'grey'}}>(Unregistered)</span>
                                                                }
                                                                </div>
                                                                {
                                                                    !["purchase", "debitNote"].includes(voucherType) &&
                                                                    <div>
                                                                        <b>Place of
                                                                            Supply: </b>{states[selectedLedger.country || "IN"][selectedLedger.state]?.name}
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={6} style={{paddingRight: 24}}>
                                                {
                                                    selectedLedgerId &&
                                                    <>
                                                        <b>Billing Address:</b>
                                                        <br/>
                                                        {selectedLedger?.address}
                                                        <br/>
                                                        {
                                                            states[selectedLedger.country || "IN"][selectedLedger.state]?.name
                                                        }
                                                        {
                                                            selectedLedger.country && selectedLedger.country !== "IN" &&
                                                                <>
                                                                    ,&nbsp;
                                                                    {countriesMap[selectedLedger.country].name}
                                                                </>
                                                        }
                                                        {selectedLedger?.phone && <>
                                                            <br/><b>Phone: </b>{selectedLedger.phone}</>}
                                                        {selectedLedger?.email && <>
                                                            <br/><b>Email: </b>{selectedLedger.email}</>}
                                                        {selectedLedger?.pan && <>
                                                            <br/><b>PAN: </b>{selectedLedger.pan}</>}
                                                    </>
                                                }
                                            </Col>
                                            {
                                                voucherType === "invoice" &&
                                            <Col span={6} style={{paddingRight: 16}}>
                                                <Form.Item
                                                    labelCol={{
                                                        span: 8, style: {
                                                            textAlign: 'left'
                                                        }
                                                    }}
                                                    style={{marginBottom: 8}}
                                                    label={"Due Date"} name={"dueDate"}
                                                    // rules={[{required: true, message: "can't be blank"}]}
                                                >
                                                    <DatePicker format={"DD MMM YYYY"} allowClear={true}/>
                                                </Form.Item>
                                                <Form.Item labelCol={{
                                                    span: 8, style: {
                                                        textAlign: 'left'
                                                    }
                                                }} label={"Reference"} name={"reference"}>
                                                    <Input placeholder={"PO no. / AWB tracking no."}/>
                                                </Form.Item>
                                            </Col>
                                            }
                                            {
                                                ["creditNote", "debitNote"].includes(voucherType) &&
                                                <Col span={6} style={{paddingRight: 16}}>
                                                    <Form.Item
                                                        labelCol={{
                                                            span: 8, style: {
                                                                textAlign: 'left'
                                                            }
                                                        }}
                                                        style={{marginBottom: 8}}
                                                        label={"Invoice"} name={"originalInvoice"}
                                                        // rules={[{required: true, message: "can't be blank"}]}
                                                    >
                                                        <Select
                                                            placeholder={"Select Invoice"}
                                                            options={
                                                                creditNoteInvoices.map(inv => ({
                                                                    label: voucherType === "creditNote" ? inv.displayDocNum : inv.externalDocumentNumber,
                                                                    value: inv._id
                                                                }))
                                                            }
                                                            allowClear={true}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item labelCol={{
                                                        span: 8, style: {
                                                            textAlign: 'left'
                                                        }
                                                    }} label={"Reason"} name={"reason"}>
                                                        <Input placeholder={`e.g. ${voucherType==="creditNote" ? "Sales" : "Purchase"} Return`}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                        </Row>
                                    </Col> :
                                    <>
                                        <Col span={12}>
                                            <Form.Item name={"ledgerDr"} label={"To"}
                                                       rules={[{required: true, message: "Please select receiver"}]}>
                                                <Select
                                                    placeholder={"Select Cash-in-hand Ledger"}
                                                    style={{width: '100%'}}
                                                    filterOption={(input, {ledger}) =>
                                                        ledger?.name?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                                        || ledger?.address?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                >
                                                    {
                                                        Object.values(ledgersMap).filter(filterDr)
                                                            .map((led) => (
                                                                <Option key={led._id} value={led._id} ledger={led}>
                                                                    {led.name}
                                                                </Option>
                                                            ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name={"ledgerCr"} label={"By"}
                                                       rules={[{required: true, message: "Please select client"}]}>
                                                <Select
                                                    placeholder={"Select Client"}
                                                    style={{width: '100%'}}
                                                    filterOption={(input, {ledger}) =>
                                                        ledger?.name?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                                        || ledger?.address?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                >
                                                    {
                                                        Object.values(ledgersMap).filter(filterCr)
                                                            .map((led) => (
                                                                <Option key={led._id} value={led._id} ledger={led}>
                                                                    <div>
                                                                        <strong>{led.name}</strong>
                                                                        <div>{led.address}</div>
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>
                            }

                        </Row>
                        {
                            !docType.haveItems ?
                                <>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item name={"amount"} label={"Amount"} rules={[{required: true}]}>
                                                <InputNumber min={1} style={{width: 300}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name={"remarks"} label={"Remarks"}
                                                       rules={[{required: true, message: "can't be blank"}]}>
                                                <Input placeholder={"e.g. Transaction ID, message, etc."}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Form.Item style={{marginTop: 8}} name="items"
                                           rules={[{
                                               required: true,
                                               message: "Document must have at least one item line."
                                           }]}>
                                    <ItemsComponent
                                        isIgst={selectedLedger?.state !== _.get(state, 'company.state')}
                                        isAbroad={selectedLedger?.country && selectedLedger?.country !== "IN"}
                                        onChange={(newItems) => setVoucherItems(newItems)}
                                    />
                                </Form.Item>
                        }
                        <Row style={{marginBottom: 28}}>
                            <Col span={15} style={{height: 'fit-content', border: '0px solid blue'}}>
                                {
                                    ["invoice", "creditNote", "purchase", "debitNote", "proforma"].includes(voucherType) &&
                                        <>
                                            <div style={{
                                                border: '1px solid lightgrey',
                                                borderRadius: 8,
                                                padding: 16,
                                                marginBottom: 8,
                                                // paddingBottom: 0,
                                                background: '#fff8e1', // E3F2FD
                                                fontWeight: 'bold',
                                                // display: 'inline'
                                            }}>
                                                { ["invoice", "proforma"].includes(voucherType) && <>
                                                <div style={{
                                                    lineHeight: '50px',
                                                    verticalAlign: 'middle',
                                                    height: 50,
                                                }}>
                                                <div style={{float: 'left', height: 32, lineHeight: '32px', verticalAlign: 'middle'}}>
                                                    Extra Discount&nbsp;
                                                    <Tooltip title={<>
                                                        Extra discount is applied on total invoice amount & it does not affect the <b>GST Tax calculations</b>. To affect tax calculations, apply item level discount.
                                                    </>}>
                                                        <QuestionCircleFilled/>
                                                    </Tooltip>
                                                    &nbsp;:
                                                </div>
                                                <Form.Item className={"zeroMargin"} name={"extraDiscountValue"}
                                                           style={{marginBottom: 8, width: "30%", float: 'left', marginRight: 8, marginLeft: 8}}
                                                    // label={"Price per unit"}
                                                    //        rules={[{required: true, message: "can't be blank"}]}
                                                >
                                                    <Input suffix={extraDiscountType==='percent' && '%'} prefix={extraDiscountType!=='percent' && RUPEE} placeholder={"Extra Discount"} style={{width: '100%'}} min={0}/>
                                                </Form.Item>

                                                {/*<center>*/}
                                                    <Tooltip style={{float: 'left', border: '1px solid pink'}} placement={"right"} title={<>
                                                        % (Percentage): Use this if you want to apply discount based on percentage.
                                                        <br/>
                                                        {RUPEE} (Fixed): Use this if you want to apply fixed amount of discount per item.
                                                    </>}>
                                                        <Form.Item className={"zeroMargin"} name={"extraDiscountType"}
                                                            // valuePropName={"checked"}
                                                                   style={{width: 'fit-content', border: "0px solid brown"}}
                                                        >
                                                            <SwitchSelect
                                                                debug={true}
                                                                checkedChildren={"%"}
                                                                unCheckedChildren={RUPEE}
                                                                valuesMap={{
                                                                    true: "percent",
                                                                    false: "fixed"
                                                                }}
                                                                reverseMap={{
                                                                    percent: true,
                                                                    fixed: false
                                                                }}
                                                                // onChange={(newVal) => console.log("## NewVal ##", newVal)}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                {/*</center>*/}
                                                </div>
                                    {/*        </div>*/}
                                    {/*<div style={{*/}
                                    {/*    border: '1px solid lightgrey',*/}
                                    {/*    borderRadius: 8,*/}
                                    {/*    padding: 8,*/}
                                    {/*    marginBottom: 8,*/}
                                    {/*    paddingBottom: 0,*/}
                                    {/*    background: '#E3F2FD',*/}
                                    {/*}}>*/}
                                        {/*<b>Payment Received ? </b>*/}
                                                <Divider style={{margin: 8}}/>
                                    { voucherType === "invoice" && <>
                                                <Row>
                                                    <Col span={8}>
                                        <Form.Item className={"zeroMargin"}
                                                   label={"Payment Received ?"}
                                                   // valuePropName={"checked"}
                                                   name={"paymentReceived"}
                                                   style={{padding: 0, margin: 0}}>
                                            <SwitchSelect
                                                checkedChildren={"Yes"}
                                                unCheckedChildren={"No"}
                                                valuesMap={{
                                                    true: true,
                                                    false: false,
                                                }}
                                                reverseMap={{
                                                    true: true,
                                                    false: false
                                                }}
                                                size={"small"}
                                            />
                                            {/*<Checkbox onChange={(e) => setCheckPaymentReceived(e.target.checked)}><h3>*/}
                                            {/*    <b>Payment Received ? </b></h3></Checkbox>*/}
                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        {
                                                            checkPaymentReceived &&
                                                            <Form layout={"horizontal"} form={formReceipt}
                                                                  labelCol={{
                                                                      span: 5, style: {
                                                                          textAlign: 'left',
                                                                      }
                                                                  }}
                                                                  wrapperCol={{span: 19}}
                                                            >
                                                                {/*<Row>*/}
                                                                {/*    <Col span={8}>*/}
                                                                <Form.Item name={"receiver"} label={"Receiver"}>
                                                                    <Select
                                                                        disabled={!checkPaymentReceived}
                                                                        placeholder={"Select Receiver"}
                                                                        style={{width: '100%'}}
                                                                        filterOption={(input, {ledger}) =>
                                                                            ledger?.name?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                                                            || ledger?.address?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                    >
                                                                        {
                                                                            Object.values(ledgersMap).filter(filterDr)
                                                                                .map((led) => (
                                                                                    <Option key={led._id}
                                                                                            value={led._id}
                                                                                            ledger={led}>
                                                                                        {led.name}
                                                                                    </Option>
                                                                                ))}
                                                                    </Select>
                                                                </Form.Item>
                                                                {/*</Col>*/}
                                                                {/*<Col span={10}>*/}
                                                                <Form.Item name={"remarks"} label={"Remarks"}>
                                                                    <Input disabled={!checkPaymentReceived}
                                                                           placeholder={"e.g. Cash received by delivery boy"}/>
                                                                </Form.Item>
                                                                {/*</Col>*/}
                                                                {/*<Col span={6}>*/}
                                                                <Form.Item name={"amount"} label={"Amount"}>
                                                                    <Input disabled={!checkPaymentReceived}
                                                                           placeholder={"Amount"}
                                                                           min={1}
                                                                           onChange={(e) => setReceivedAmount(e.target.value)}/>
                                                                </Form.Item>
                                                                {/*    </Col>*/}
                                                                {/*</Row>*/}
                                                                {
                                                                    receiptError &&
                                                                    <p style={{color: 'red'}}>{receiptError}</p>
                                                                }
                                                            </Form>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Divider style={{margin: 8}}/>


                                                <Form.Item className={"zeroMargin"} style={{marginTop: 16, border: '0px solid red'}} name={"bankAccount"}
                                                           label={"Select Bank Account"}
                                                        //    rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Bank Account has to be selected"
                                                        //     },
                                                        // ]}
                                                >
                                                               <Select
                                                               options= {Object.values(ledgersMap).filter(isBankLedger).map(ledger => ({
                                                                   label: ledger.name,
                                                                   value: ledger._id,
                                                               }))}
                                                               />
                                                </Form.Item>
                                                <Divider style={{margin: 8}}/>
                                        </>}
                                                </>}
                                                <Form.Item className={"zeroMargin"} style={{marginTop: 16, border: '0px solid red'}} name={"isTaxLess"}
                                                           label={"Hide GST/ Taxes"}>
                                                    <SwitchSelect
                                                        checkedChildren={"Yes"}
                                                        unCheckedChildren={"No"}
                                                        valuesMap={{
                                                            true: true,
                                                            false: false
                                                        }}
                                                        reverseMap={{true: true, false: false}}
                                                    />
                                                </Form.Item>
                                                <Divider style={{margin: 8}}/>
                                                <Form.Item className={"zeroMargin"} style={{marginTop: 16, border: '0px solid red'}} name={"privateNote"}
                                                           label={"Private note (not visible on invoice)"}>
                                                    <Input.TextArea rows={2}
                                                                    style={{
                                                                        resize: 'none',
                                                                        // border: '1px solid black',
                                                                        borderRadius: 8,
                                                                        fontStyle: 'italic',
                                                                    }}
                                                                    placeholder={"Private note. e.g. Material sent through courier, purchase order no., pending payment, etc."}/>
                                                </Form.Item>
                                    </div>
                                        </>
                                }
                            </Col>
                            {
                                ["invoice", "proforma", "creditNote", "purchase", "debitNote"].includes(voucherType) &&
                                <Col span={8} offset={1} style={{
                                    fontSize: 15,
                                    background: '#e0f7fa',
                                    borderRadius: 8,
                                    padding: 12,
                                    border: '1px solid lightgrey',
                                    height: 'fit-content'
                                }}>
                                    <Row><Col span={12}>Subtotal:</Col><Col style={{textAlign: 'right'}} span={12}>₹ {subtotal}</Col></Row>
                                    <Row><Col span={12}>Total GST:</Col><Col style={{textAlign: 'right'}} span={12}>₹ {totalTax}</Col></Row>
                                    {/*<Row><Col span={12}>SGST:</Col><Col style={{textAlign: 'right'}} span={12}>₹ {totalCgst}</Col></Row>*/}
                                    {/*<Row><Col span={12}>IGST:</Col><Col style={{textAlign: 'right'}} span={12}>₹ {totalIgst}</Col></Row>*/}
                                    {
                                        extraDiscount ?
                                        <Row><Col span={16}>{extraDiscount.title}:</Col><Col style={{textAlign: 'right'}} span={8}>₹ -{extraDiscount.amount}</Col></Row> :
                                            undefined
                                    }
                                    <Row>
                                        <Col span={12}>
                                            <Row align={"middle"}>
                                                <Col>Rounded Off:&nbsp;&nbsp;</Col>
                                                <Col>
                                                    <Form.Item name={"enableRoundOff"} style={{margin: 0, padding: 0}}>
                                                        <SwitchSelect
                                                            defaultChecked={true}
                                                            checkedChildren={"On"}
                                                            unCheckedChildren={"Off"}
                                                            valuesMap={{
                                                                true: true,
                                                                false: false
                                                            }}
                                                            reverseMap={{
                                                                true: true,
                                                                false: false
                                                            }}
                                                            size={"small"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{textAlign: 'right'}} span={12}>₹ {roundedOff}</Col></Row>
                                    <Row style={{fontWeight: 'bold'}}><Col span={12}>Total:</Col><Col style={{textAlign: 'right'}}
                                        span={12}>₹ {roundedTotal}</Col></Row>
                                    {
                                        checkPaymentReceived &&
                                            <>
                                                <Row><Col span={12}>Payment Received:</Col><Col style={{textAlign: 'right'}}
                                                                                                span={12}>₹ {actualReceived}</Col></Row>
                                                <Row style={{fontWeight: 'bold'}}><Col span={12}>Amount Due:</Col><Col style={{textAlign: 'right'}}
                                                                                                                       span={12}>₹ {(roundedTotal - actualReceived).toFixed(0)}</Col></Row>
                                            </>
                                    }
                                </Col>
                            }
                        </Row>

                        <div style={{marginTop: 16}}>
                            <h3>Attachments <Tooltip title={"Attachments can be used to store customer-signed receivings, purchase bills, or other related document."}><InfoCircleOutlined/></Tooltip></h3>
                            <Form.Item name={"attachments"}>
                                <VoucherAttachments />
                            </Form.Item>
                        </div>
                    </Form>
                </div>


            </div>

            <div style={{
                position: 'fixed', width: '100%',
                height: 62,
                bottom: 0,
                background: '#e0f2f1',
                borderTop: '1px solid lightgrey',
                verticalAlign: 'middle',
                lineHeight: '58px',
                paddingLeft: 20,
                paddingRight: (siderCollapsed ? 80 : 200) + 32,
                // paddingBottom: 8,
                textAlign: 'right',
                // border: '1px solid blue'
            }}>
                <span style={{
                    fontSize: 18,
                    marginRight: 16,
                    color: 'green',
                }}>
                    Total: <b>{RUPEE} {beautifyAmount(roundedTotal)}</b>
                </span>
                <ButtonV2 style={{height: 40, fontWeight: 'bold', fontSize: 16}} className={"customBorder"} type={"primary"} onClick={async () => {
                    // console.log("## Flag 0 ##");
                    // const clientVals = await formClient.validateFields();
                    console.log("## Flag 1 ##");
                    const obj = await form.validateFields();
                    console.log("## Flag 2 ##", obj);
                    console.log("## Flag 3 ##", obj);
                    let receiptData;
                    if (obj.paymentReceived) {
                        receiptData = await formReceipt.validateFields();
                        console.log("## ReceiptForm ##", receiptData);
                        if (!receiptData.receiver) {
                            setReceiptError("Please select Receiver");
                            return;
                        } else if (!receiptData.amount) {
                            setReceiptError("Amount should be valid number greater than 0")
                            return;
                        }
                    }
                    if (obj?.bankAccount) {
                       obj.customConfig={};
                       obj.customConfig.bankAccount = obj.bankAccount;
                       delete obj.bankAccount;
                    }
                    _.set(obj, 'customConfig.enableRoundOff', obj.enableRoundOff);
                    _.set(obj, 'customConfig.isTaxLess', obj.isTaxLess);
                    console.log("## Receipt ##", receiptData);

                    // return;
                    if (!isNewVoucher) {
                        obj._id = voucher._id;
                        obj.receiptRef = voucher.receiptRef;
                    }
                    if (docType.haveItems) {
                        obj.amount = roundedTotal;
                    }
                    (obj.items || []).forEach(item => {
                        delete item.editMode;
                        delete item.newMode;
                    });
                    obj.type = voucherType;
                    if (!["invoice", "proforma", "delivery", "creditNote", "purchase", "debitNote"].includes(voucherType)) {
                        // will keep adding in this list, when feature is developed with new design
                        // ToDo - handle documentNumber for purchase bills (will do at time of doing task of complete review of purchase bill)
                        message.error("Feature is disabled for your account, please contact customer service.");
                        return;
                    }
                    // return;
                    const result = await saveVoucher({
                        voucher: obj,
                        receiptData,
                    });
                    if (_.get(result, 'data.success')) {
                        trackEvent(EMpEventName.FEATURE_USED, {
                            featureName: isNewVoucher ? "Create Voucher" : "Edit Voucher",
                        });
                        const vid = isNewVoucher ? _.get(result, 'data.data.voucher._id') : voucher._id;
                        message.success("Document Saved successfully!");
                        await loginRequest("", "", dispatch); //
                        navigate(`/vouchers/${voucherType}`);
                        if (["invoice", "proforma", "deliveryChallan", "creditNote", "debitNote"].includes(voucherType)) {
                            dispatch({
                                popData: {
                                    visible: true,
                                    type: "previewInvoice",
                                    title: "Preview", // `Send Invoice ${invoiceNum} on Email`,
                                    data: {
                                        voucherId: `${vid}?t=${Date.now()}`,
                                        type: "invoice"
                                    }
                                }
                            });
                        }
                    } else {
                        message.error(_.get(result, 'data.message'));
                    }

                }}>Save{!["purchase"].includes(voucherType) && " & Preview"}</ButtonV2>
                &nbsp;&nbsp;&nbsp;
                <Button style={{height: 40,
                    fontWeight: 'bold', fontSize: 16,
                }} className={"customBorder"} type={"danger"}><Link
                    to={`/vouchers/${voucherType}`}>Cancel</Link></Button>

            </div>

        </>
    )
};

export default VoucherWrite;
