import React, {useEffect, useState} from "react";
import moment from "moment";
import {useParams, useNavigate} from "react-router-dom";
import { exportReports } from "../../services/api";
import {
    Alert,
    Button, Card,
    Col,
    DatePicker, Divider,
    Dropdown,
    Menu, message,
    PageHeader,
    Popover,
    Row, Statistic,
    Table, Tooltip,
    Switch
} from "antd";
import {getInternalLedgerStatement, sendSMS} from "../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {
    beautifyAmount,
    copyToClipboard,
    customDispatcher,
    getPublicShareLink,
    isValidPhone,
    PublicShareLinks
} from "../../utils";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    FileExcelOutlined, FilePdfOutlined,
    LinkOutlined,
    MailFilled,
    MessageFilled,
    ShareAltOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import {RUPEE} from "../../models/constants";
import {ACTION_TYPES, MyActionButton, RECORD_TYPES} from "../../utils/actionButtons";
import { ButtonV2 } from "../../components/ButtonV2";


const {RangePicker} = DatePicker;

const defaultRangeFrom = moment().month('April').startOf('month');
const defaultRangeTo = moment();

const typesMap = {
    invoice: "Sale",
    receipt: "Receipt",
    purchase: "Purchase",
    payment: "Payment",
    creditNote: "Credit Note",
    debitNote: "Debit Note",
}

const LedgerStatement = (props) => {
    const navigate = useNavigate();
    const state = useSelector(state => state.oldState);
    const {company} = state || {};
    const dispatch = customDispatcher(useDispatch());
    const params = useParams();
    const {ledgerId: searchId} = params;
    const [data, setData] = useState({
        statement: [],
        ledger: {},
        summary: {}
    });
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [itemWiseDetails, setItemWiseDetails] = useState("no");

    const ledgerId = data.ledger._id || "";
    const {ledger = {}} = data || {};

    const [range, setRange] = useState({
        from: defaultRangeFrom,
        to: defaultRangeTo,
    });
    const [loading, setLoading] = useState(false);

    const {isPublicView, setCompany} = props;

    useEffect(() => {
        const fetchStatement = async () => {
            setLoading(true);
            const resp = await getInternalLedgerStatement(searchId, range.from.valueOf(), range.to.valueOf(), itemWiseDetails);
            setData(resp.data.data);
            setLoading(false);
            if (isPublicView){
              setCompany(resp.data.data.company)
            }
        }

        if (state && searchId) {
            fetchStatement();
        }
    }, [state, searchId, range, itemWiseDetails, isPublicView, setCompany]);

    const OnRedirect = (data) => {
        navigate(`/pay/${data}/gateway`);
    }

    const printAmount = amount => <span>
        <b style={{color: (amount > 0 ? 'red' : 'green')}}>
            {/*{amount > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/> }*/}
            ₹ {beautifyAmount(amount > 0 ? amount : -amount)}</b>
    </span>;

    const cols = [
        {
            title: "Date",
            render: obj => obj.isSpecial ? obj.specialText : moment(obj.date).format("DD MMM YYYY")
        },
        {
            title: "Type",
            render: (obj) => <span>{typesMap[obj.type] || ""}</span>,
        },
        {
            title: "Voucher #",
            dataIndex: "displayDocNum",
        },
        {
            title: "Debit",
            render: ({amount}) => amount > 0 && printAmount(amount)
        },
        {
            title: "Credit",
            render: ({amount}) => amount <= 0 && printAmount(amount)
        },
        {
            title: "Closing Balance",
            render: ({closingBalance, isItemRow}) => <div>
              {isItemRow ? "" : <>
                {printAmount(closingBalance)} <span style={{color: closingBalance>0 ? 'red' : 'green'}}>({closingBalance > 0 ? "Dr" : "Cr"})</span>
              </>}
            </div>
        },
        
    ];

    if (itemWiseDetails === "yes") {
      cols.push(...[
        {
          title: "Item Name",
          dataIndex: "itemName",
        },
        {
          title: "Qty",
          dataIndex: "itemQty",
        },
        // {
        //   title: "Discount",
        //   dataIndex: "itemDiscount",
        // },
        {
          title: "Rate",
          dataIndex: "itemPrice",
        },
        {
          title: "Taxable",
          dataIndex: "itemTaxable",
        },
        {
          title: "GST",
          dataIndex: "itemTotalTax",
        },
        {
          title: "Total",
          dataIndex: "itemTotal",
        },
      ]);
    }

    if (!props.isPublicView) {
      cols.push({
        title: "Actions",
        render: (obj) =>
          obj.isSpecial ? (
            ""
          ) : (
            <>
              <MyActionButton
                recordType={RECORD_TYPES.VOUCHER}
                actionType={ACTION_TYPES.DETAILS}
                record={obj}
              />
              &nbsp;
              <MyActionButton
                recordType={RECORD_TYPES.VOUCHER}
                actionType={ACTION_TYPES.DELETE}
                record={obj}
              />
            </>
          ),
      });
    }
  

    const publicLink = getPublicShareLink(PublicShareLinks.LEDGER, ledger.hashId);

    return (
      <div
        style={{
          padding: 20,
          paddingTop: 0,
          paddingBottom: 0,
          border: "0px solid green",
        }}
      >
        <Row
          style={{ border: "0px solid red", marginBottom: 8, marginTop: 8 }}
          justify={"center"}
          align={"middle"}
        >
          {!props.isPublicView ? (
            <Col span={12}>
              <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title={`Statement - ${ledger.name || ""}`}
                style={{
                  margin: 0,
                  padding: 0,
                }}
              />
            </Col>
          ) : (
            <Col span={12}>
              <PageHeader
                title={`${!props.isPublicView ? "" : ""}${ledger.name || ""}`}
                style={{
                  margin: 0,
                  padding: 0,
                }}
              />
            </Col>
          )}
          <Col span={12} style={{ paddingRight: 16, textAlign: "right" }}>
            {props.isPublicView &&
              data.summary?.closing > 0 &&
              data.company?.pgConfig?.enabled && (
                <Button
                  className={"btnCustom4 borderLightGrey hoverBlueBorder"}
                  style={{
                    background: "rgb(229 226 241)",
                    color: "blue",
                    padding: 0,
                    height: 28,
                    fontWeight: "bold",
                    fontSize: 13,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  onClick={(e) => {
                    dispatch({
                      popData: {
                        visible: true,
                        height: 200,
                        placement: "bottom",
                        type: "payNow",
                        title: "Make Payment",
                        data: {
                          payAmount: data.summary?.closing || "",
                          ledgerId: ledgerId,
                        },
                        onRedirect: OnRedirect,
                      },
                    });
                  }}
                >
                  {" "}
                  Pay Now
                </Button>
              )}
            &nbsp;
            <Tooltip title={"Download as Excel File"}>
              <Button
                className={"btnCustom4 borderLightGrey hoverOrangeBorder"}
                style={{
                  background: "#ffecb3",
                  color: "#ff6f00",
                  padding: 0,
                  height: 28,
                  fontWeight: "bold",
                  fontSize: 13,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
                onClick={(e) => {
                  // console.log("## Btn Clicked ##");
                  // message.info("Feature under maintenance");
                  // const link = document.createElement('a');
                  // link.href = `http://app.dev.xp/api/internal/export?ledgerId=${ledgerId}&from=${range.from.valueOf()}&to=${range.to.valueOf()}&type=ledgerStatement`;
                  // link.click();

                  exportReports({
                    ledgerId: ledgerId,

                    type: "ledgerStatement",
                    from: range.from.valueOf(),
                    to: range.to.valueOf(),
                    itemWiseDetails,
                  })
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      // console.log("url is",url)
                      const link = document.createElement("a");
                      link.href = url;
                      // const timeStr = moment().format("DDMMYYYYHHmmss");
                      // let fileName;
                      // if (data.type === "gstExcel") {
                      //     fileName = `GST_Reports_${timeStr}.zip`
                      // } else {
                      //     fileName = `Sales_${timeStr}.xlsx`;
                      // }
                      link.setAttribute("download", "ledger_statement.xlsx");
                      // Append to html link element page
                      document.body.appendChild(link);
                      // Start download
                      link.click();
                      // Clean up and remove the link
                      link.parentNode.removeChild(link);
                    });
                }}
              >
                <FileExcelOutlined style={{ color: "#ff6f00" }} /> Excel
              </Button>
            </Tooltip>
            &nbsp;
            <Tooltip title={"View or Download PDF"}>
              <Button
                className={"btnCustom4 borderLightGrey hoverRedBorder"}
                style={{
                  background: "#ffebee",
                  color: "#b71c1c",
                  padding: 0,
                  height: 28,
                  fontWeight: "bold",
                  fontSize: 13,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
                onClick={(e) => {
                  dispatch({
                    popData: {
                      visible: true,
                      type: "previewInvoice",
                      title: "Ledger Statement PDF",
                      data: {
                        documentId: ledgerId,
                        options: {
                          type: "ledgerStatement",
                          from: range.from.valueOf(),
                          to: range.to.valueOf(),
                          itemWiseDetails,
                        },
                      },
                    },
                  });
                }}
              >
                <FilePdfOutlined style={{ color: "#ff6f00" }} /> PDF
              </Button>
            </Tooltip>
            &nbsp;
            <Dropdown
              overlay={
                <Menu className={"menuWrapper"}>
                  <Menu.Item
                    className={"customHover"}
                    onClick={() => {
                      // e.stopPropagation();
                      const propsData = ledger
                      propsData.ledger = true
                      propsData.from = moment(range.from.valueOf()).format('YYYY-MM-DD')
                      propsData.to = moment(range.to.valueOf()).format('YYYY-MM-DD')
                      propsData.startDate = range.from.valueOf()
                      propsData.endDate = range.to.valueOf()
                      dispatch({
                        popData: {
                          visible: true,
                          type: "email",
                          title: `Send Ledger on Email`,
                          data: propsData,
                        },
                      });
                    }}
                  >
                    <div style={{ fontWeight: "bold", width: "100%" }}>
                      <MailFilled style={{ fontSize: 18, color: "red" }} />
                      &nbsp;&nbsp;Email
                    </div>
                  </Menu.Item>
                  <Popover
                    style={{ width: "100%" }}
                    content={
                      <div onClick={(e) => e.stopPropagation()}>
                        <Alert
                          type={"info"}
                          style={{ background: "white", border: 0, width: 500 }}
                          showIcon={false}
                          message={
                            <>
                              <div>
                                `Hi {ledger?.name}, Thanks for doing your
                                business with us, kindly find your ledger
                                statement in attached document in{" "}
                                {moment(range.from.valueOf()).format(
                                  "YYYY-MM-DD"
                                )}{" "}
                                to{" "}
                                {moment(range.to.valueOf()).format(
                                  "YYYY-MM-DD"
                                )}
                                `
                              </div>
                              <br />
                              <ButtonV2
                                type={"primary"}
                                style={{ fontSize: 12, padding: 4, height: 26 }}
                                onClick={async () => {
                                  console.log("ledger is ------->>>", ledger);
                                  // e.stopPropagation();
                                  //   const party =
                                  //     voucherType === "receipt"
                                  //       ? voucher.crParty
                                  //       : voucher.party;
                                  //   console.log("party", party);
                                  //   const phoneString = isValidPhone(party?.phone)
                                  //     ? `${party?.phone}`
                                  //     : "";
                                  //   if (!isValidPhone(phoneString)) {
                                  //     message.error(
                                  //       `Customer contact is not a valid mobile number`
                                  //     );
                                  //     return;
                                  //   }
                                  //   console.log("voucher", voucher);

                                  const resp = await sendSMS(
                                    {
                                      origin: "EXTERNAL_KB_APP",
                                      phone: ledger?.phone,
                                        smsTemplate: "KB_WHATSAPP_SEND_VOUCHER",
                                        params: {
                                          voucherLink:
                                            "https://app.khatabuddy.com/api/internal/pdf/" +
                                            ledger?._id,
                                          documentType: "Document",
                                          customerName: ledger?.name || " ",
                                          amount: `${RUPEE} ${ledger.balance}`,
                                          url: ledger?.hashId,
                                          action: "ledger",
                                          voucherFileName:"ledgerstatement.pdf"
                                        }
                                    },
                                    "whatsapp"
                                  );
                                  if (!resp.data.success) {
                                    message.error(resp.data.message);
                                  } else {
                                    message.success(
                                      "Document sent successfully!"
                                    );
                                  }
                                }}
                              >
                                Send on whatsapp
                              </ButtonV2>
                            </>
                          }
                        />
                      </div>
                    }
                    title=""
                    trigger="click"
                    onVisibleChange={(visible) => {}}
                  >
                    <Menu.Item
                      className={"customHover"}
                      style={{ fontWeight: "bold" }}
                      onClick={async (menuInfo) => {}}
                    >
                      <div style={{ width: "100%" }}>
                        <WhatsAppOutlined
                          style={{
                            color: "green",
                            fontSize: 18,
                            cursor: "pointer",
                          }}
                        />
                        &nbsp;&nbsp;Whatsapp
                      </div>
                    </Menu.Item>
                  </Popover>
                  <Popover
                    style={{ width: "100%" }}
                    content={
                      <div onClick={(e) => e.stopPropagation()}>
                        <Alert
                          type={"info"}
                          style={{ background: "white", border: 0, width: 500 }}
                          showIcon={false}
                          message={
                            <>
                              <div>`Hi {ledger?.name}, Thanks for doing your
                                business with us, kindly find your ledger
                                statement in attached document in{" "}
                                {moment(range.from.valueOf()).format(
                                  "YYYY-MM-DD"
                                )}{" "}
                                to{" "}
                                {moment(range.to.valueOf()).format(
                                  "YYYY-MM-DD"
                                )}
                                `</div>
                              <br />
                              <ButtonV2
                                type={"primary"}
                                style={{ fontSize: 12, padding: 4, height: 26 }}
                                onClick={async () => {
                                    console.log("send on sms",ledger?.phone )
                                  if (
                                    !isValidPhone(ledger?.phone)
                                  ) {
                                    message.error(
                                      `Customer contact is not a valid mobile number`
                                    );
                                    return;
                                  }
                                  let resp;
                                  resp = await sendSMS(
                                    {
                                      origin: "EXTERNAL_KB_APP",
                                      phone: ledger?.phone,
                                      smsTemplate: "KB_SEND_INVOICE",
                                      params: {
                                        customerName: ledger.name || "customer",
                                        merchant: company?.name || "",
                                        invoiceNum: "TEST",
                                        amount: `Rs. 500`,
                                        url: publicLink,
                                      },
                                    },
                                    "sms"
                                  );
                                  if (!resp.data.success) {
                                    message.error(resp.data.message);
                                  } else {
                                    message.success(
                                      ` Document sent on SMS successfully!`
                                    );
                                  }
                                }}
                              >
                                Send on SMS
                              </ButtonV2>
                            </>
                          }
                        />
                      </div>
                    }
                    title=""
                    trigger="click"
                    onVisibleChange={(visible) =>{}
                    //   setShowPaymentTooltip(!visible)
                    }
                  >
                    <Menu.Item
                      className={"customHover"}
                      style={{ fontWeight: "bold" }}
                    >
                      <div style={{ width: "100%" }}>
                        <MessageFilled
                          style={{
                            color: "blue",
                            fontSize: 18,
                            cursor: "pointer",
                          }}
                          onClick={async (e) => {
                            e.stopPropagation();
                          }}
                        />
                        &nbsp;&nbsp;SMS
                      </div>
                    </Menu.Item>
                  </Popover>

                  <Menu.Item
                    className={"customHover"}
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      copyToClipboard(publicLink);
                      message.success("Link Copied");
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LinkOutlined
                        style={{
                          color: "#e040fb",
                          fontSize: 18,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {}}
                      />
                      &nbsp;&nbsp;Copy Link
                    </div>
                  </Menu.Item>
                </Menu>
              }
            >
              <span
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {!props.isPublicView && (
                  <Button
                    className={"btnCustom4 borderLightGrey"}
                    style={{
                      background: "#e8f5e9",
                      color: "#1b5e20",
                      padding: 0,
                      height: 28,
                      fontWeight: "bold",
                      fontSize: 13,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ShareAltOutlined /> Send
                  </Button>
                )}
              </span>
            </Dropdown>
            &nbsp;&nbsp;
            <Popover
              content={
                <div style={{padding: '8px'}}>
                  <div style={{marginBottom: '8px'}}>
                    <span style={{marginRight: '8px'}}>Include Item-wise Details:</span>
                    <Switch
                      checked={itemWiseDetails === "yes"}
                      onChange={(checked) => setItemWiseDetails(checked ? "yes" : "no")}
                    />
                  </div>
                </div>
              }
              title="Advanced Options"
              trigger="click"
              visible={showAdvanced}
              onVisibleChange={setShowAdvanced}
            >
              <Button
                className={"btnCustom4 borderLightGrey"}
                style={{
                  background: "#f5f5f5",
                  padding: 0,
                  height: 28,
                  fontWeight: "bold",
                  fontSize: 13,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                Advanced
              </Button>
            </Popover>
            &nbsp;&nbsp;
            <RangePicker
              defaultValue={[defaultRangeFrom, defaultRangeTo]}
              allowClear={false}
              format={"DD MMM YYYY"}
              onChange={(dates) => {
                const [_from, _to] = dates || [];
                if (_from && _to) {
                  setRange({
                    from: _from,
                    to: _to,
                  });
                }
              }}
            />
          </Col>
        </Row>

        <Divider />
        <Row style={{}}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Previous Balance"
                value={beautifyAmount(data.summary?.opening || "")}
                // precision={0}
                valueStyle={{
                  color: data.summary?.opening <= 0 ? "#3f8600" : "#cf1322",
                }}
                prefix={RUPEE}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Total Debit (+)"
                value={beautifyAmount(data.summary?.totalDr || "")}
                valueStyle={{ color: "#cf1322" }}
                prefix={
                  <>
                    <ArrowUpOutlined /> {RUPEE}
                  </>
                }
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Total Credit (-)"
                value={beautifyAmount(data.summary?.totalCr || "")}
                valueStyle={{ color: "#3f8600" }}
                prefix={
                  <>
                    <ArrowDownOutlined /> {RUPEE}
                  </>
                }
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Net Closing Balance"
                value={beautifyAmount(data.summary?.closing || "")}
                // precision={2}
                valueStyle={{
                  color: data.summary?.closing <= 0 ? "#3f8600" : "#cf1322",
                }}
                prefix={RUPEE}
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Table
          columns={cols}
          dataSource={data.records}
          loading={loading}
          pagination={false}
        />
      </div>
    );
};

export default LedgerStatement;
