import React, {useEffect, useState} from 'react';
import {
    Table,
    Button,
    Row,
    Col,
    Input,
    Divider, DatePicker,Segmented, Tag, Empty
} from 'antd';
import {getVouchers, deleteVoucher} from '../../services/api';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    PlusOutlined,
    DollarOutlined,
    CheckCircleOutlined, ClockCircleOutlined, SettingOutlined
} from "@ant-design/icons";
import moment from "moment";

import {beautifyAmount, customDispatcher} from "../../utils";
import {RUPEE, voucherTypes} from "../../models/constants";
import {saleColumns} from "./sales";
import { getDateRange, saveDateRange} from '../../services/cookies';
import { getPresetDateRange } from '../../services/functions';


const {RangePicker} = DatePicker;

let fetchingToken;
const fetchRecordsStatic = async ({voucherType, setRecords, setLoading}) => {
    const currentToken = Date.now();
    fetchingToken = currentToken;
    const {data: {data: {vouchers: data}}} = await getVouchers(voucherType);

    if (currentToken === fetchingToken) { // to avoid race condition, quick switching windows
        setRecords(data);
        setLoading(false);
    }
}

const Vouchers = () => {
    const dateRange = getDateRange()
    const params = useParams();
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const {voucherType} = params;
    const docType = voucherTypes[voucherType];
    const state = useSelector(state => state.oldState);
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterClient, setFilterClient] = useState("");
    const [filterDate, setFilterDate] = useState(dateRange);
    const [showPaymentTooltip, setShowPaymentTooltip] = useState(true);
    const [selectedSegment, setSelectedSegment] = useState("all");

    const applySegmentFilter = (record) => {
        const balance = record.amount - (record.paid || 0);
        if (selectedSegment === "all") {
            return true;
        } else if (selectedSegment === "pending" && balance > 0) {
            return true;
        } else if (selectedSegment === "paid" && balance <= 0) {
            return true;
        }
        return false;
    }


    const applyFiltersOnVoucher = (record) => {
        let docNum = docType.idType !== "mixed" ? record.documentNumber : `${record.documentNumber.prefix || ""}${record.documentNumber.num}`;
        if (typeof docNum !== "string") {
            docNum = docNum.toString();
        }
        const filterByInvoice = docNum?.toLowerCase().includes(filterClient.toLowerCase());
        const filterByClient = record.partyName?.toLowerCase().includes(filterClient.toLowerCase());
        let filterByDate = true;
        const [start, end] = filterDate || [];
        if (start && end) {
            
            // console.log("Start Date:", start);
            // console.log("End Date:", end);
            // console.log("Record Date:", moment(record.date));
    
            filterByDate = moment(record.date).isBetween(start.startOf('day'), end.endOf('day'),null, '[]');
        }
        return (filterByInvoice || filterByClient) && filterByDate;
    }

    const filteredRecords = records.filter(applySegmentFilter);

    let total = 0, paid = 0;
    filteredRecords.filter(applyFiltersOnVoucher).forEach(r => {
        total += r.amount;
        paid += (r.paid || 0);
    });

    const pending = total - paid;


    const selectComponentClass = (props) => {
        return (
            <div style={{ width: '100%', textAlign: 'right', marginLeft: 16 }}>
                <Tag style={{fontSize: 14, padding: 6}} color={"#108ee9"} icon={<DollarOutlined/>}>
                    Total: <b>{RUPEE} {beautifyAmount(total.toFixed())}</b>
                </Tag>

                {
                    voucherType === "invoice" &&
                        <>
                            <Tag style={{fontSize: 14, padding: 6}} color={"#5c9444"} icon={<CheckCircleOutlined/>}>
                                Paid: <b>{RUPEE} {beautifyAmount(paid.toFixed())}</b>
                            </Tag>

                            <Tag style={{fontSize: 14, padding: 6}} color={"#f50"} icon={<ClockCircleOutlined/>}>
                                Pending: <b>{RUPEE} {beautifyAmount(pending.toFixed())}</b>
                            </Tag>

                        </>
                }

            </div>
        );
    };


    const fetchRecords = () => fetchRecordsStatic({voucherType, setRecords, setLoading});

    useEffect(() => {
        console.log("## State Changed Effect ##");
        if (state.company) {
            setLoading(true);
            fetchRecordsStatic({voucherType, setRecords, setLoading});
        }
    }, [state, setRecords, voucherType]);


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    {docType.plural}
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <Button className={"btnCustomOrange"} onClick={() => {
                        dispatch({
                            popData: {
                                visible: true,
                                type: "documentSettings",
                                title: "Document Settings", // `Send Invoice ${invoiceNum} on Email`,
                            }
                        });
                    }} style={{
                        // background: 'darkslateblue',
                        // color: 'white',
                        borderRadius: 6,
                        fontWeight: 'bold',
                    }}>
                        <SettingOutlined style={{}}/> Settings
                    </Button>

                    {
                        docType.buttons.includes("create") &&
                            <>
                                &nbsp;&nbsp;
                                <Button type={"primary"} onClick={() => {
                                    navigate(`/vouchers/${voucherType}/create`)
                                }} style={{
                                    // background: 'darkslateblue',
                                    // color: 'white',
                                    borderRadius: 6,
                                    fontWeight: 'bold',
                                }}>
                                    <PlusOutlined style={{}}/> New {docType.label || docType.name}
                                </Button>
                            </>
                    }
                    {
                        docType.buttons.includes("receipt") &&
                            <>
                                &nbsp;&nbsp;
                                <Button className={"btnCustom2"} onClick={() => {
                                dispatch({
                                    popData: {
                                        visible: true,
                                        type: "receiptWrite",
                                        title: "Record Payment",
                                    }
                                });
                            }}
                            >
                                <PlusOutlined style={{}}/> Record Payment
                            </Button>
                            </>
                    }
                </Col>
            </Row>
            <Divider style={{margin: 4, marginBottom: 12}}/>
            <div style={{padding: 0}}>
                {/*<h2 style={{float: 'left'}}>{docType?.plural}</h2>*/}
                <Row style={{border: '0px solid red'}}>
                    <Col span={24}>
                        {
                            ["invoice"].includes(voucherType) &&
                        <>
                        <Segmented
                            onChange={setSelectedSegment}
                            options={[
                                {
                                    label: <div>
                                        All
                                    </div>, value: "all"
                                },
                                {label: "Paid", value: "paid"},
                                {label: "Pending", value: "pending"},
                                // {label: "Cancelled", value: "cancelled"}
                            ]}
                        />

                            &emsp;
                        </>
                        }
                        <Input onChange={(e) => setFilterClient(e.target.value)}
                               placeholder={voucherType === "purchase" ? "Vendor Name" : `Search with ${docType.idTitle || "Document #"} or Customer Name`}
                               style={{width: 'auto', minWidth: 500}}
                               allowClear/>
                        &emsp;
                        <RangePicker style={{}} format={"DD MMM YYYY"}
                                    
                                    defaultValue={dateRange}
                                    ranges={getPresetDateRange()}
                                    onChange={(val) => {
                                        setFilterDate(val);
                                        val ? saveDateRange(val) : saveDateRange([moment()]);
                                     }
                                     
                                     }/>

                    </Col>
                </Row>
            </div>

            <br/>


                    <Table
                        loading={loading}
                        pagination={{
                            selectComponentClass,
                            showSizeChanger: true, // voucherType === "invoice",
                            position: ["bottomLeft"]
                        }}
                        rowClassName={"tableSalesRow"}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    dispatch({
                                        popData: {
                                            visible: true,
                                            type: "invoice",
                                            title: "Invoice Details",
                                            data: record
                                        }
                                    })
                                }, // click row
                            };
                        }}
                        scroll={{y: '70vh'}}
                        columns={
                            saleColumns(docType, filterClient, voucherType, filterDate, filterClient, deleteVoucher, fetchRecords, showPaymentTooltip, setShowPaymentTooltip, state?.company, dispatch, navigate)
                        }
                        dataSource={filteredRecords}
                        locale={{ emptyText:
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={"No Data available between selected Date Range, kindly change the date range"}
                                />
                            }}
                    />

        </div>
    );
}

export default Vouchers;
