import {
    Alert,
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Image,
    Input,
    message,
    Radio,
    Result,
    Row,
    Select,
    Spin
} from "antd";
import {Popup, Steps} from "antd-mobile";
import lodash from "lodash";
import logoTi from "../../res/img/logo_ti.png";
import {addressStates, PRIMARY_COLOR} from "../../models/constants";
import {ClockCircleFilled, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    getSelfcareKycCurrentStatus,
    sendSelfcareKycAadhaarOtp,
    sendSelfcareKycPhoneOtp, submitManualKycDetails, submitSelfcareKycAadhaarOtp,
    submitSelfcareKycPhoneOtp
} from "../../services/api";
import {iAssert, isValidNumber, isValidPhone} from "../../utils";
import { ButtonV2 } from "../../components/ButtonV2";
import {UploadImage} from "../../components/UploadImage";

const {Step} = Steps;

const getStepDescription = (currentProgress, currentStep) => {
    if (currentProgress > currentStep) {
        return "Finished";
    } else if (currentProgress === currentStep) {
        return "In Progress";
    }
    return "Waiting";
}

const Step1UI = ({setInitiated, setUseExisting, userHash, existingKycRecord, loading, setLoading}) => {
    const [showManualProcess, setShowManualProcess] = useState(false);
    const closePopup = () => setShowManualProcess(false);

    const backLink = <Link to={`/selfcare/${userHash}`}>
        <u>&larr;&nbsp;Go back to Portal Home</u>
    </Link>;

    if (existingKycRecord) {
        const {adhName, adhNum, recordId} = existingKycRecord;
        return <div>
            <Card title="Use Existing KYC"
                  // extra={<a href="#">More</a>}
                  style={{ width: '100%' }}>
                <p>We found below KYC record in your account, which is already approved:</p>
                <p>
                    <h3>
                        Name: {adhName}
                        <br/>
                        Aadhaar: {adhNum}
                    </h3>
                </p>
                <p>Do you want to link same KYC record with this device ?</p>
                
                <p>
                    <Button type={"primary"} onClick={() => setInitiated(true)}>No, Use New Document</Button>
                    &nbsp;&nbsp;
                    <Button loading={loading} type={"primary"} onClick={() => setUseExisting(true)}>Yes</Button>
                </p>
            </Card>
            <br/>
            {backLink}
        </div>
    }

    return <div>
        <Alert
            style={{marginTop: 20, border: 0, background: '#eeeeee'}}
            message="What documents do I need ?"
            description={
                <div>
                    e-KYC is just 2-steps OTP-based digital process.
                    <br/>
                    {/*<ul>*/}
                    <br/>
                    <li>1. Validate your primary phone number using OTP</li>
                    <br/>
                    <li>2. Validate your identity using Aadhaar number & OTP on mobile registered with Aadhaar</li>
                    {/*</ul>*/}
                </div>
            }
            type="info"
            showIcon
        />

        <br/>
        <Button onClick={() => setShowManualProcess(true)} type={"default"} style={{height: 40, width: '40%', marginLeft: '5%', borderRadius: 8}}>Manual KYC</Button>
        <Button onClick={() => setInitiated(true)} type={"primary"} style={{height: 40, width: '40%', marginLeft: '10%', borderRadius: 8}}>Start KYC</Button>
        <br/><br/>
        {backLink}
        <Popup
            visible={showManualProcess}
            onMaskClick={closePopup}
        >
            <div style={{padding: 8, paddingTop: 16}}>
            <h1>Manual KYC Process</h1>
            Please email us at care@trakinfinity.com with below mentioned information:
                <ul>
                    <li>GPS Device ID</li>
                    <li>Your Full Name (as mentioned on govt. ID)</li>
                    <li>Your Primary Contact Number</li>
                    <li>Full Address with Pincode & State</li>
                    <li>Govt ID Document No. (e.g. Aadhaar number)</li>
                </ul>
                Mail Attachments:
                <ul>
                    <li>Photo of Authorized Signatory</li>
                    <li>Photos of Govt. ID (Front + Back) [Supported IDs: Aadhaar, Driving License, Voter ID only]</li>
                </ul>
                <br/>
                Manual KYC review may take 5-7 working days.
            </div>
            <Button onClick={closePopup} type={"primary"} style={{height: 40, width: '80%', marginLeft: '10%', borderRadius: 8, marginBottom: 16}}>Okay</Button>
            <br/>
        </Popup>
    </div>
}


const Step2UI = ({kycLink, existingKycRecord, useExisting, setKycLink, loading, setLoading}) => {
    const [form] = Form.useForm();
    const [subStep, setSubStep] = useState();

    useEffect(() => {
        form.setFieldsValue({
            phone: kycLink?.gpsDevice?.CustomerMobile || "",
        });
    }, [kycLink]);

    if (useExisting) {
        return <Step2ExistingKycUI useExisting={useExisting} existingKycRecord={existingKycRecord} kycLink={kycLink} setKycLink={setKycLink} loading={loading} setLoading={setLoading}/>;
    }

    const sendOtp = async () => {
        try {
            setLoading(true);
            const {phone, email} = await form.validateFields();
            if (!isValidPhone(phone)) {
                message.error(`Invalid Phone`);
                return;
            }
            const response = await sendSelfcareKycPhoneOtp({
                deviceId: kycLink?.deviceId,
                email,
                primaryContact: phone,
            });
            iAssert(response?.data?.success, "API Failed");
            message.success("OTP Sent");
            setSubStep(1);
        } catch(err) {
            console.error(`Failed to send OTP`, err);
            message.error(`Failed to send OTP, please retry after sometime`);
        } finally {
            setLoading(false);
        }
    }

    const verifyOtp = async () => {
        try {
            setLoading(true);
            const {phone, inputOtp} = await form.validateFields();
            if (!isValidNumber(inputOtp, 4)) {
                message.error(`Invalid OTP input`);
                return;
            }
            const response = await submitSelfcareKycPhoneOtp({
                deviceId: kycLink?.deviceId,
                primaryContact: phone,
                inputOtp,
            });
            iAssert(response?.data?.success, response?.data?.message);
            setKycLink(response?.data?.data?.kycLink);
            message.success("OTP verified successfully");
        } catch(err) {
            console.error(`Failed to verify OTP`, err);
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    }



    return (
        <div style={{width: '100%'}}>
            <Form form={form} style={{width: '100%'}}>
                <Form.Item label={"Email (Optional)"} name={"email"} style={{width: '100%'}}>
                    <Input type={"email"} placeholder={"Email ID"} disabled={!!subStep || loading}/>
                </Form.Item>
                <Form.Item label={"Your Primary Contact"} name={"phone"} style={{width: '100%'}}>
                    <Input maxLength={10} placeholder={"10-digit Phone Number"} disabled={!!subStep || loading}/>
                </Form.Item>
                {
                    subStep &&
                    <Form.Item name={"inputOtp"} label={"Enter OTP"} style={{width: '100%'}}>
                        <Input maxLength={4} placeholder={"4-digit OTP"}/>
                    </Form.Item>
                }
                <Button loading={loading} onClick={subStep ? verifyOtp : sendOtp} type={"primary"}
                        style={{height: 40, width: '80%', borderRadius: 8}}>{
                    subStep ? "Verify OTP" : "Send OTP"
                }</Button>
            </Form>
        </div>
    )
}


const Step2ExistingKycUI = ({existingKycRecord, kycLink, setKycLink, loading, setLoading}) => {
    const [form] = Form.useForm();
    const [subStep, setSubStep] = useState();

    useEffect(() => {
        form.setFieldsValue({
            aadhaar: existingKycRecord.adhNum,
            name: existingKycRecord.adhName,
            phone: existingKycRecord.primaryContact,
        });
    }, [kycLink, existingKycRecord]);

    const sendOtp = async () => {
        try {
            setLoading(true);
            // const {phone, email} = await form.validateFields();
            // if (!isValidPhone(phone)) {
            //     message.error(`Invalid Phone`);
            //     return;
            // }
            const response = await sendSelfcareKycPhoneOtp({
                deviceId: kycLink?.deviceId,
                recordId: existingKycRecord.recordId,
            });
            iAssert(response?.data?.success, "API Failed");
            message.success("OTP Sent");
            setSubStep(1);
        } catch(err) {
            console.error(`Failed to send OTP`, err);
            message.error(`Failed to send OTP, please retry after sometime`);
        } finally {
            setLoading(false);
        }
    }

    const verifyOtp = async () => {
        try {
            setLoading(true);
            const {inputOtp} = await form.validateFields();
            if (!isValidNumber(inputOtp, 4)) {
                message.error(`Invalid OTP input`);
                return;
            }
            const response = await submitSelfcareKycPhoneOtp({
                deviceId: kycLink?.deviceId,
                recordId: existingKycRecord.recordId,
                inputOtp,
            });
            iAssert(response?.data?.success, response?.data?.message);
            setKycLink(response?.data?.data?.kycLink);
            message.success("OTP verified successfully");
        } catch(err) {
            console.error(`Failed to verify OTP`, err);
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    }



    return (
        <div style={{width: '100%'}}>
            <p>
                Please authorise us to link below mentioned KYC record with this device.
            </p>
            <Form form={form} style={{width: '100%'}}>
                <Form.Item label={"Full Name (as mentioned on Aadhaar)"} name={"name"} style={{width: '100%'}}>
                    <Input type={"text"} placeholder={"Full Name"} disabled={true}/>
                </Form.Item>
                <Form.Item label={"Aadhaar Number"} name={"aadhaar"} style={{width: '100%'}}>
                    <Input maxLength={14} placeholder={"12-digit Aadhaar Number"} disabled={true}/>
                </Form.Item>
                <Form.Item label={"Your Primary Contact"} name={"phone"} style={{width: '100%'}}>
                    <Input maxLength={10} placeholder={"10-digit Phone Number"} disabled={true}/>
                </Form.Item>
                {
                    subStep &&
                    <Form.Item name={"inputOtp"} label={"Enter OTP"} style={{width: '100%'}}>
                        <Input maxLength={4} placeholder={"4-digit OTP"}/>
                    </Form.Item>
                }
                <Button loading={loading} onClick={subStep ? verifyOtp : sendOtp} type={"primary"}
                        style={{height: 40, width: '80%', borderRadius: 8}}>{
                    subStep ? "Verify OTP" : "Send OTP"
                }</Button>
            </Form>
        </div>
    )
}


const Step3UI = ({kycLink, setKycLink, loading, setLoading}) => {
    const [form] = Form.useForm();
    const [manualKycForm] = Form.useForm();
    const [subStep, setSubStep] = useState();
    const [selectedDocType, setSelectedDocType] = useState("aadhaar");
    const [kycMode, setKycMode] = useState("eKyc");

    const sendOtp = async () => {
        try {
            setLoading(true);
            const {docNum} = await form.validateFields();
            if (!isValidNumber(docNum, 12)) {
                message.error(`Invalid Aadhaar number`);
                return;
            }
            const response = await sendSelfcareKycAadhaarOtp({
                deviceId: kycLink?.deviceId,
                docNum,
            });
            iAssert(response?.data?.success, "API Failed");
            console.log("## replyMsg ##", response?.data?.data);
            if (response?.data?.data !== "OTP sent successfully") {
                message.error(response?.data?.data);
                return;
            }
            // if (response?.data?.data?.includes?.("not linked")) {
            //     message.error(`Mobile not registered with this Aadhaar, please follow Manual Process`);
            //     return;
            // } else if (response?.data?.data?.includes?.("Invalid Aadhaar Card")) {
            //     message.error(`Mobile not registered with this Aadhaar, please follow Manual Process`);
            //     return;
            // }
            message.success("OTP Sent to mobile registered with Aadhaar");
            setSubStep(1);
        } catch(err) {
            console.error(`Failed to send Aadhaar OTP`, err);
            message.error(`Failed to send Aadhaar OTP, please retry after sometime`);
        } finally {
            setLoading(false);
        }
    }

    const verifyOtp = async () => {
        try {
            setLoading(true);
            const {docNum, inputOtp} = await form.validateFields();
            if (!isValidNumber(inputOtp, 6)) {
                message.error(`Invalid OTP input`);
                return;
            }
            const response = await submitSelfcareKycAadhaarOtp({
                deviceId: kycLink?.deviceId,
                inputOtp,
            });

            iAssert(response?.data?.success, response?.data?.message);
            setKycLink(response?.data?.data?.kycLink);
            message.success("OTP verified successfully");
        } catch(err) {
            console.error(`Failed to verify OTP`, err);
            message.error(err.message || "OTP is invalid or expired");
        } finally {
            setLoading(false);
        }
    }

    const defaultFormRules = [{required: true, message: "can't be blank"}];

    return (
        <div style={{width: '100%'}}>
            <Radio.Group onChange={(e) => setKycMode(e.target.value)} defaultValue="eKyc" buttonStyle="solid">
                <Radio.Button value="eKyc">e-KYC</Radio.Button>
                <Radio.Button value="manual">Manual</Radio.Button>
            </Radio.Group>
            {/*{*/}
            {/*    kycMode === "eaaKyc" ?*/}
                    <Form form={form} style={{width: '100%', display: kycMode === "eKyc" ? "block" : "none"}}>
                        <Form.Item name={"docNum"} label={"Enter Aadhaar Number (without space/dashes)"} style={{width: '100%'}}>
                            <Input maxLength={12} placeholder={"12-digit Aadhaar Number"} disabled={!!subStep || loading}/>
                        </Form.Item>
                        {
                            subStep &&
                            <>
                                <Form.Item name={"inputOtp"} label={"Enter OTP (received on mobile registered with Aadhaar)"} style={{width: '100%'}}>
                                    <Input maxLength={6} placeholder={"6-digit OTP"}/>
                                </Form.Item>
                                <p style={{fontSize: 12, textAlign: 'left'}}>By submitting OTP, you provide consent to share information linked to Aadhaar with us, for GPS Device KYC purpose, and this information will not be used for any other purpose.</p>
                            </>
                        }
                        <Button loading={loading} onClick={subStep ? verifyOtp : sendOtp} type={"primary"} style={{height: 40, width: '80%', borderRadius: 8}}>
                            {subStep ? "Verify OTP" : "Send OTP"}
                        </Button>
                    </Form>
                    {/*:*/}
                    <Form style={{display: kycMode === "eKyc" ? "none" : "block"}} form={manualKycForm} initialValues={{
                        state: "DL",
                        documentType: "aadhaar",
                    }}>
                        <Form.Item name={"name"} label={"Full Name"} style={{width: '100%'}} rules={defaultFormRules}>
                            <Input maxLength={12} placeholder={"Full Name"} />
                        </Form.Item>
                        <Form.Item name={"address"} label={"Full Address"} style={{width: '100%'}} rules={defaultFormRules}>
                            <Input maxLength={200} placeholder={"Full Address"} />
                        </Form.Item>
                        <Form.Item name={"state"} label={"State"} style={{width: '100%', textAlign: 'left'}} rules={defaultFormRules}>
                            <Select
                                options={lodash.map(addressStates.IN, state => ({label: state.name, value: state.alphaCode}))}
                                allowClear={true}
                                filterOption={(input, option) => {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                showSearch
                            />
                        </Form.Item>
                        <Form.Item name={"selfPic"} label={"Photo (Current latest photo of individual Person)"} style={{width: '100%', textAlign: 'left'}} rules={defaultFormRules}>
                            <UploadImage altTag={"Photo"}/>
                        </Form.Item>
                        <Form.Item name={"vehicleRc"} label={"Vehicle RC (Registration Certificate)"} style={{width: '100%', textAlign: 'left'}} rules={defaultFormRules}>
                            <UploadImage altTag={"RC"}/>
                        </Form.Item>
                        <Form.Item name={"documentType"} label={"ID Proof Type"} style={{width: '100%', textAlign: 'left'}} rules={defaultFormRules}>
                            <Select
                                onChange={(selectedType) => setSelectedDocType(selectedType)}
                                options={[
                                    {value: "aadhaar", label: "Aadhaar"},
                                    {value: "dl", label: "Driving License"},
                                    {value: "voterId", label: "Voter ID"},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item name={"documentNum"} label={"Document ID Number"} style={{width: '100%'}} rules={defaultFormRules}>
                            <Input maxLength={30} placeholder={"ID Number"} />
                        </Form.Item>
                        <Row>
                            <Col span={12}>
                                <Form.Item name={"idProofImg1"} label={"ID Proof photo (Front)"} style={{width: '100%', textAlign: 'left'}} rules={defaultFormRules}>
                                    <UploadImage altTag={"ID Proof Front"}/>
                                </Form.Item>
                            </Col>
                            {
                                selectedDocType === "aadhaar" &&
                                <Col span={12}>
                                    <Form.Item name={"idProofImg2"} label={"ID Proof photo (Back)"} style={{width: '100%', textAlign: 'left'}} rules={defaultFormRules}>
                                        <UploadImage altTag={"ID Proof Back"}/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>

                        <br/>
                        <p style={{fontSize: 12, textAlign: 'left'}}>By submitting OTP, you provide consent to share this information for GPS Device KYC purpose, and this information will not be used for any other purpose.</p>
                        <Button type={"primary"}
                                  onClick={async () => {
                                      const values = await manualKycForm.validateFields();
                                      console.log("## manual Vals ##", values);
                                      // return;
                                      const response = await submitManualKycDetails({
                                          deviceId: kycLink?.deviceId,
                                          userData: values,
                                      });
                                      if (response.data.success) {
                                          setKycLink(response?.data?.data?.kycLink);
                                          message.success("Details Submitted successfully");
                                      } else {
                                          message.error(`Something went wrong, please contact customer care`);
                                      }
                                  }}
                                  style={{height: 40, width: '80%', borderRadius: 8}}>
                            Submit Details
                        </Button>
                        <br/>
                        <br/>
                    </Form>
            {/*}*/}

        </div>
    )
}

const Step4UI = ({kycLink}) => {
    const navigate = useNavigate();
    const params = useParams();

    return <div>
        <Result
            status="success"
            icon={kycLink?.status === "inReview" ? <ClockCircleFilled style={{color: 'orange'}}/> : null}
            title={
                <div style={{fontSize: 18, fontWeight: 'bold'}}>
                    {
                        kycLink?.status === "inReview" ?
                            <>
                                Great, your KYC details have been submitted and are under review.
                                <br/>
                                <span style={{fontSize: 14, fontWeight: 'normal'}}>(It may take upto 5-7 working days)</span>
                            </> :
                            `Great, you have completed KYC process for this GPS Device!`
                    }
                </div>
            }
            // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[
                <Button  onClick={() => {
                    navigate(`/selfcare/${params.customerId}`);
                }} type={"primary"} style={{height: 40, width: '80%', borderRadius: 8}}>Visit Portal Home</Button>
            ]}
        />
    </div>
}

const stepsMap = ({setInitiated, useExisting, setUseExisting, userHash, kycLink, existingKycRecord, setKycLink, loading, setLoading}) => [
    <Step1UI setInitiated={setInitiated} setUseExisting={setUseExisting} userHash={userHash} existingKycRecord={existingKycRecord}/>,
    <Step2UI useExisting={useExisting} existingKycRecord={existingKycRecord} kycLink={kycLink} setKycLink={setKycLink} loading={loading} setLoading={setLoading}/>,
    <Step3UI kycLink={kycLink} setKycLink={setKycLink} loading={loading} setLoading={setLoading}/>,
    <Step4UI kycLink={kycLink} />,
];

export const TiKycPage = () => {
    const params = useParams();
    const {deviceId, customerId: userHash} = params;

    const [pageData, setPageData] = useState();
    const [initiated, setInitiated] = useState(false);
    const [useExisting, setUseExisting] = useState(false);
    const [loading, setLoading] = useState(false);
    const {kycLink, existingKycRecord} = pageData || {};
    const setKycLink = (_kycLink) => setPageData({kycLink: _kycLink});
    console.log("## KycPageData ##", pageData);

    useEffect(() => {
        (async () => {
            const response = await getSelfcareKycCurrentStatus(deviceId);
            setPageData(response.data.data);
        })();
    }, []);

    let currentStepIndex = 0;
    if (kycLink?.status === "approved" || kycLink?.status === "inReview") {
        currentStepIndex = 3;
    } else if (kycLink?.currentStep === "verifyIdRecord") {
        currentStepIndex = 2;
    } else if (initiated) {
        currentStepIndex = 1;
    } else if (useExisting) {
        currentStepIndex = 1;
    }

    return (
        <div style={{background: '#eeeeee', height: '100%', minHeight: '100vh'}}>
            <div style={{padding: 12}}>
                <Row align={"middle"} justify={"center"} style={{border: '0px solid black'}}>
                    <Col span={24} style={{maxWidth: '18cm', border: '0px solid red'}}>
                        <Row>
                            <Col span={8}>
                                <Image
                                    // onClick={() => handleSecretKey("L")}
                                    preview={false}
                                    src={logoTi}
                                    style={{maxWidth: '100%', width: 168, padding: 8}}/>
                            </Col>
                            <Col span={16} style={{paddingRight: 8, textAlign: 'right'}}>
                                {/*<h2 style={{fontWeight: 'bold'}}>*/}
                                {/*    /!*{"Infinite Telematics Pvt Ltd"}*!/*/}
                                {/*</h2>*/}
                                {/*&nbsp;&nbsp;*/}
                                <br/>
                                <a href={"tel:8010666611"}>
                                    <Avatar size={40} style={{backgroundColor: PRIMARY_COLOR}} icon={<PhoneOutlined/>}/>
                                </a>
                                &nbsp;&nbsp;
                                <a href={"https://wa.me/918010666611?text=I%20need%20help%20in%20KYC%20process"}>
                                    <Avatar size={40} style={{backgroundColor: '#87d068'}} icon={<WhatsAppOutlined/>}/>
                                </a>
                            </Col>

                        </Row>
                    </Col>
                    <Divider/>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <h2>KYC Progress ({deviceId})</h2>
                    </Col>
                </Row>

                {
                    pageData ?
                    <>
                        <Row>
                            <Steps direction={"horizontal"} current={currentStepIndex}
                                   style={{width: '100%', border: '0px solid green'}}>
                                <Step title={<b>Initiate<br/>KYC</b>}
                                      description={getStepDescription(currentStepIndex, 0)}/>
                                {
                                    (useExisting) ?
                                        <Step title={<b>Authorise<br/>Existing KYC</b>}
                                              description={getStepDescription(currentStepIndex, 1)}/>
                                        :
                                            <Step title={<b>Validate<br/>Phone</b>}
                                                  description={getStepDescription(currentStepIndex, 1)}/>
                                }
                                {
                                    !useExisting &&
                                    <Step title={<b>Validate<br/>Govt ID</b>}
                                          description={getStepDescription(currentStepIndex, 2)}/>
                                }
                            </Steps>
                        </Row>
                        <br/>
                        <br/>
                        <Row style={{textAlign: currentStepIndex ? 'center' : 'left', width: '100%'}}>
                            {stepsMap({setInitiated, useExisting, setUseExisting, userHash, kycLink, existingKycRecord, setKycLink, loading, setLoading})[currentStepIndex]}
                            {/*<Step4UI/>*/}
                        </Row>
                    </> :
                        <div style={{width: '100%', textAlign: 'center', marginTop: 50}}>
                        <Spin size={"large"} />
                        </div>
                }
            </div>
        </div>
    )
}