import React, { useEffect, useState } from "react";

import BrandingHeader from "./components/BrandingHeader";
import {Button, Col, Input, Row} from "antd";
import {PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import ItemGrid from "./components/ItemGrid";
import CartFooter from "./components/CartFooter";
import {isMobileView} from "../../utils";
import { getInternalCompany } from "../../services/api";
import { increementStore } from "../../services/api/ecommerce";
import {useParams} from 'react-router-dom';
import { getCart, saveCart } from "../../services/cookies";
import _ from 'lodash';
import { StoreImage } from "./components/storeImage";

const PhoneNumber = ({number}) => {
    return (
        <div style={{fontSize: 18}}>
            <span style={{
                border: '1px solid grey', padding: 8,
                fontWeight: 'bold',
                color: '#333',
                background: '#eee',
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
            }}>
               <a href={'tel:'+number} style={{"color":"ff6f00"}}> <PhoneOutlined/></a>
            </span>
            <span style={{
                border: '1px solid grey', padding: 8,
                fontWeight: 'bold',
                color: '#333',
                background: '#eee',
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                borderLeft: 0,
            }}>
               
                <a href={'tel:'+number} style={{"color":"ff6f00"}}>{number}</a>
            </span>

        </div>
    )
}

const StoreHome = () => {
    const isMobile = isMobileView();
    let { companyId } = useParams()
    const [storeName, setStoreName] = useState();
    const [storeDescription, setStoreDescription] = useState();
    const [storeImage, setStoreImage] = useState();
    const [storeNumber, setStoreNumber] = useState();
    const [productItems, setProductItems] = useState([]);
    const [filterProducts, setFilterProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [storeId, setStoreId] = useState(companyId);

    const filterProductsBasedOnSearch = (e)=>{

        if(!e || _.isEmpty(e)){
            setFilterProducts([...productItems])
        }
        const newFilteredProducts =  productItems.filter((product) => {
            const productName = product.name
            const re = new RegExp(e, 'ig');
            if(re.test(productName)){
                return product;
            }
            return '';
        })

        setFilterProducts(newFilteredProducts);
     }

     const addItemToCart = (item)=>{
        let itemsInCart = cart || []
        const cartItems = itemsInCart.find((product)=> product.name === item.name);
        if(cartItems){
            if(item.qty<=0 || !item.qty){
                itemsInCart = itemsInCart.filter((product)=> product.name !== item.name)
            }else{
                itemsInCart.forEach((product) => {
                    if(product.name === item.name){
                        product.qty = item.qty;
                    }
                })
            }
        } else {
            itemsInCart.push(item);
        }
        setCart([...itemsInCart]);
        saveCart(itemsInCart);
     }



    useEffect(()=>{
        const getStoreInformation = async()=>{
            try{
                const response = await getInternalCompany(storeId);
                const storeInformation = _.get(response,'data.data.companies.0', {})
                if(!storeInformation || _.isEmpty(storeInformation)){
                    throw new Error('Invalid Store');
                }
                const items = storeInformation.items;
                setProductItems(items);
                setFilterProducts(items);
                let companyId = storeInformation._id;
                setStoreId(companyId)
                let description = storeInformation?.ecommerceOptions?.businessDescription || ''
                let storeImage = `/api/internal/file/${storeInformation?.imgLogo}` || 'https://vyaparwebsiteimages.vypcdn.in/catalogue/firm-logo-placeholder.png'
                setStoreName(storeInformation.name);
                setStoreDescription(description)
                setStoreNumber(storeInformation?.contactNumber);
                setStoreImage(storeImage)
            }catch(e){
                window.location.href = 'https://khatabuddy.com/';
            }
        }

        const increementStoreVisitCount = ()=>{
            try{
                let body = {
                    companyId: storeId
                }
                increementStore(body);
            }catch(e){
                console.error('Error in Updating Count', e);
            }
        }
        (async function StoreDetail(){
            await getStoreInformation();
            await increementStoreVisitCount();
            setCart(getCart())
        })();
    },[storeId])

    return (
        <div>
            {
                !isMobile &&
                <BrandingHeader/>
            }

            <div style={{background: '#fff', width: '100%', paddingLeft: isMobile ? 12 : "11%", paddingRight: isMobile ? 12 : "11%", paddingBottom: 100}}>
                <Row style={{borderBottom: '1px solid lightgrey', padding: isMobile ? 8 : 32}}>
                    <Col span={16}>
                        {
                            isMobile ?
                                <>
                                    <h2 style={{fontWeight: 'bold', marginBottom: 0}}>{storeName}</h2>
                                    <p style={{fontSize: 13}}>{storeDescription}</p>
                                </> :
                                <>
                                    <h1 style={{fontWeight: 'bold'}}>{storeName}</h1>
                                    <p style={{fontSize: 15}}>{storeDescription}</p>
                                    <PhoneNumber number={storeNumber}/>
                                </>
                        }
                    </Col>
                    <Col span={8} style={{textAlign: 'right'}}>
                        <StoreImage image={storeImage} fallback="https://vyaparwebsiteimages.vypcdn.in/catalogue/firm-logo-placeholder.png"/>
                    </Col>
                    {
                        isMobile && <Col span={24}>
                            <Row style={{marginTop: 12}} justify={"space-around"}>
                                <Button className={"btnCustom4 hoverOrangeBorder borderLightGrey"}
                                        style={{
                                            background: '#ffecb3',
                                            color: '#ff6f00',
                                            padding: 0,
                                            height: 28,
                                            fontWeight: 'bold',
                                            fontSize: 13,
                                            paddingLeft: 8,
                                            paddingRight: 8
                                        }}
                                ><a href={'tel:'+storeNumber} style={{"color":"ff6f00"}}><PhoneOutlined/> Call Store</a>
                                </Button>
                                <Button className={"btnCustom4 borderLightGrey"}
                                        style={{
                                            background: '#e8f5e9',
                                            color: '#1b5e20',
                                            padding: 0,
                                            height: 28,
                                            fontWeight: 'bold',
                                            fontSize: 13,
                                            paddingLeft: 8,
                                            paddingRight: 8
                                        }}
                                        onClick={(e)=>{
                                            window.open(`https://api.whatsapp.com/send?phone=${storeNumber}&text=%22hi%22`, '_blank');
                                        }}
                                ><WhatsAppOutlined/> Whatsapp
                                </Button>
                            </Row>
                        </Col>
                    }
                </Row>

                <Row style={{marginTop: 32}} justify={"center"}>
                    <Col span={isMobile ? 24 : 12}>
                        <Input.Search size={isMobile ? "normal" : "large"} placeholder="Search for an item" onChange={(e)=>{filterProductsBasedOnSearch(e.target.value)}} onPressEnter = {(e)=>{filterProductsBasedOnSearch(e.target.value)}} onSearch={(search) => {filterProductsBasedOnSearch(search)}} enterButton />
                    </Col>
                </Row>

                <Row style={{marginTop: 32}} justify={"center"}>
                    {
                        filterProducts.map(product => <ItemGrid item={product} onChange={addItemToCart}/>)
                    }

                </Row>
            </div>

            {
                cart.length &&
                <CartFooter id={storeId}/>
            }

        </div>
    )
};

export default StoreHome;
