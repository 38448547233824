import React, { useEffect, useState } from "react";
import { Table, Tag, Divider, Row, Col, Segmented } from "antd";
import { ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {beautifyAmount, customDispatcher} from "../../utils";
import {useDispatch} from "react-redux";
import moment from "moment";
import { fetchOrders } from '../../services/api/ecommerce';
import _ from 'lodash';

const orderStatus = {
  "pending" : {
    icon: <ClockCircleOutlined/>,
    color: "warning"
  },
  "cancelled":{
    icon: <CloseCircleOutlined/>,
    color: "error"
  }, 
  "completed":{
    icon: <CheckCircleOutlined/>,
    color: "success"
  }
}


const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (createdDate, record) => {
      return(
      <div style={{ color: "#686565" }}>
        {moment(record.createdDate).format("DD MMM YYYY hh:mm A")}
      </div>
      )},
    sorter: (a, b) =>{
      return moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf()
    },
    defaultSortOrder: "descend",
  },
  {
    title: "Order Id",
    dataIndex: "orderId",
    key: "orderId",
    render: (record, val)=>(
      <div>
        KB-{val.hashId}
      </div>
       
    )
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (obj, record) =>{
      return(
        <div>
        <b>{record?.customerDetails?.name}</b>
        <br/>
        <div style={{fontSize: 11, color: 'grey'}}>
            {record?.customerDetails?.address}
        </div>
        </div>
      )
    } 
  },
  {
    title: "Order Amount",
    dataIndex: "orderAmount",
    key: "orderAmount",
    render: (obj, record) =>{
      return(
        <b style={{color: 'green'}}>₹ {beautifyAmount(record.amount)}</b>
      )
    } 
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      return (
        <span >
            <Tag color={orderStatus[status]?.color} icon={orderStatus[status]?.icon}>
              {status? status.charAt(0).toUpperCase() + status.slice(1): ''}
            </Tag>
        </span>
      );
    },
  },
];

const EcommerceOrders = () => {

  const [records, setRecords] = useState([]);
  const [segment, setSegment] = useState();
  const dispatch = customDispatcher(useDispatch());


  const getOrders = async()=>{
    try{
      const response = await fetchOrders();
      const orders = _.get(response, 'data.data.orders', [])
      setRecords([...orders]);

    }catch(e){
      console.error('Error in getting orders', e);
    }
  }

  const onSelectSegment =(record)=>{
    if(!segment){
      return record
    }
    if(segment && record.status === segment){
      return record;
    }
  }

  useEffect(()=>{
    getOrders()
  },[])
  const updateTable = ()=>{
    getOrders();
  };

  const showRecordsBasedOnSelection = (selected)=>{
    setSegment(selected);
   
  }

  return (
    <div>
      <div style={{ paddingTop: 8 }}>
        <Row justify={"left"} align={"left"}>
          <Col span={12} style={{ fontSize: 24, fontWeight: "bold" }}>
            Ecommerce Store
          </Col>
        </Row>
      </div>
      <Divider style={{ marginBottom: 18 }} />

      <div style={{ paddingBottom: "10" }}>
        <Segmented
          onChange={showRecordsBasedOnSelection}
          options={[
            {
              label: <div>All</div>,
              value: "",
            },
            { label: "Pending", value: "pending" },
            { label: "Completed", value: "completed" },
            { label: "Cancelled", value: "cancelled" },
          ]}
        />
      </div>
      <div style={{ paddingBottom: "10", paddingTop: 30 }}>
        <Table
          columns={columns}
          dataSource={records.filter(onSelectSegment)}
          rowClassName={"tableSalesRow"}
          pagination={{
            position: "bottomRight",
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                dispatch({
                  popData: {
                    visible: true,
                    type: "ecommerce",
                    title: "Order",
                    data: {
                      selectedRecord: record,
                    },
                    onSave: updateTable,
                  },
                });
              },
            };
          }}
        />
      </div>
    </div>
  );
};

export default EcommerceOrders;
