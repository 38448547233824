import React, {useEffect, useState} from "react";
import {Button, Input, Row, Col, Form, message, Divider} from "antd";
import { useMediaQuery } from "react-responsive";
import {useDispatch} from "react-redux";
import {customDispatcher, isErpWeb, isPuc} from "../utils";
import {forgotPassword, googleLogin, loginRequest, signup} from "../services/api";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import loginBack from "../res/img/leftBanner.png";
import {GoogleLogin} from 'react-google-login';
import logo from "../res/img/logoLandscapeV3.png";
import imgIndia from "../res/img/madeInIndia.png";
import {CustomerServiceFilled} from '@ant-design/icons';
import { ButtonV2 } from "../components/ButtonV2";

const LoginComponent = ({setView}) => {
    const [form] = Form.useForm();
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const onGoogleLogin = async ({profileObj}) => {
        try {
            // data required:: email, name, phone, createCompany, companyName
            const signupData = {
                email: profileObj.email,
                name: profileObj.name,
                phone: "",
                createCompany: true,
                companyName: ""
            };
            const gData = await googleLogin(signupData);
            const {email, token} = gData.data.data;
            const user = await loginRequest(email, token, dispatch);
            if (user) { // logged in
                navigate("/dashboard");
            }
            message.success("Login Successful");
        } catch (err) {
            console.error("## Google Login Failed ##", err);
            message.error(`Google login failed. Please retry or login using email/ password.`);
        }
    }
    return (
        <div style={{width: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
            <h1 style={{fontWeight: 'bold'}}>Login</h1>
            <div style={{color: '#666', paddingBottom: 32, fontSize: 16}}>Sign in to KhataBuddy with Google or email
                address
            </div>
            <div style={{textAlign: 'center', color: "#888"}}>
                {
                    !isPuc() && !isErpWeb() &&
                    <>
                    <GoogleLogin
                        clientId="342300141684-boqeg9bphb51s88di8pfjl94nmp3umcc.apps.googleusercontent.com"
                        // render={renderProps => (
                        //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                        // )}
                        buttonText={<b>Sign in with Google</b>}
                        onSuccess={onGoogleLogin}
                        onFailure={() => message.error(`Google login failed. Please retry or login using email/ password.`)}
                        cookiePolicy={'single_host_origin'}
                        style={{background: 'red', width: '100%'}}
                    />
                    <br/><br/>
                    <div style={{textAlign: 'center', verticalAlign: 'middle', lineHeight: '30px'}}>
                    <div style={{width: '30%', border: '0.5px solid #aaa', marginTop: 14, float: 'left'}}></div>
                    <div style={{width: '40%', float: 'left'}}>or Sign in with Email</div>
                    <div style={{width: '30%', border: '0.5px solid #aaa', marginTop: 14, float: 'left'}}></div>
                    </div>
                    </>
                }

                <br/><br/>
            </div>
            <Form
                form={form}
                layout={"vertical"}
            >
                <Form.Item name={"username"} label={"Username (Email address or Mobile)"}
                           rules={[{required: true, type: isErpWeb() ? undefined : "email" && undefined, message: "Please enter a valid email"}]}>
                    <Input style={{borderRadius: 100, height: 40, paddingLeft: 24}} placeholder={"Email address"}
                           autoFocus/>
                </Form.Item>
                <Form.Item name={"password"} label={"Password"}
                           rules={[{required: true, message: "can't be blank"}]}>
                    <Input.Password style={{borderRadius: 100, height: 40, paddingLeft: 24}} placeholder={"Password"}/>
                </Form.Item>
                <ButtonV2 htmlType={"submit"} type={"primary"} style={{height: 40, borderRadius: 5, width: '100%'}} onClick={async () => {
                    const obj = await form.validateFields();
                    const {username, password} = obj;
                    const user = await loginRequest(username, password, dispatch, "web");
                    if (user) { // logged in
                        if (isErpWeb()) {
                            navigate("/installations");
                        } else {
                            navigate("/dashboard");
                        }
                    }
                }}>Sign In</ButtonV2>
            </Form>


            {
                !isErpWeb() &&
                <div style={{marginTop: 16}}>
                    <div style={{float: 'left'}}>
                        Don't have an account ? <Link to={"#"} onClick={() => setView('signup')}><strong>Sign up</strong></Link>
                    </div>
                    <div style={{float: 'right'}}>
                        <Link to={"#"} onClick={() => setView('reset')}><strong>Forgot Password ?</strong></Link>
                    </div>
                </div>
            }
        </div>
    )
}

const SignupComponent = ({setView}) => {
    const [form] = Form.useForm();
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const onGoogleLogin = async ({profileObj}) => {
        try {
            // data required:: email, name, phone, createCompany, companyName
            const signupData = {
                email: profileObj.email,
                name: profileObj.name,
                phone: "",
                createCompany: true,
                companyName: ""
            };
            const gData = await googleLogin(signupData);
            const {email, token} = gData.data.data;
            const user = await loginRequest(email, token, dispatch);
            if (user) { // logged in
                navigate("/dashboard");
            }
            message.success("Login Successful");
        } catch (err) {
            console.error("## Google Login Failed ##", err);
            message.error(`Google login failed. Please retry or login using email/ password.`);
        }
    }

    return (
        <div style={{width: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
            <h1>Sign up</h1>
            <div style={{color: '#666', paddingBottom: 32}}>Sign up to KhataBuddy with your google account or
                email address
            </div>
            <div style={{textAlign: 'center', color: "#666"}}>
                <GoogleLogin
                    clientId="342300141684-boqeg9bphb51s88di8pfjl94nmp3umcc.apps.googleusercontent.com"
                    // render={renderProps => (
                    //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                    // )}
                    buttonText={<b>Sign up with Google</b>}
                    onSuccess={onGoogleLogin}
                    onFailure={() => message.error(`Google login failed. Please retry or login using email/ password.`)}
                    cookiePolicy={'single_host_origin'}
                    style={{background: 'red', width: '100%'}}
                />
                <br/><br/>
                Or
                <br/><br/>
            </div>
            <Form
                form={form}
                layout={"vertical"}
            >
                <Row>
                    <Col span={12} style={{paddingRight: 16}}>
                        <Form.Item
                            name="name"
                            label="Your Full Name"
                            rules={[
                                {
                                    required: true,
                                    message: "can't be blank"
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{paddingLeft: 16}}>
                        <Form.Item
                            name={"companyName"}
                            label={"Business Name"}
                            rules={[{required: true, message: "can't be blank"}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{paddingRight: 16}}>
                        <Form.Item
                            name={"email"}
                            label={"Email (Username)"}
                            rules={[{required: true, type: "email", message: "Please enter a valid email id"}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{paddingLeft: 16}}>
                        <Form.Item
                            name={"phone"}
                            label={"Phone"}
                            rules={[{required: true, message: "Please enter a valid phone"}]}
                        >
                            <Input maxLength={10}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{paddingRight: 16}}>
                        <Form.Item
                            name={"password"}
                            label={"Password"}
                            rules={[{required: true, message: "can't be blank"}]}
                        >
                            <Input.Password/>
                        </Form.Item>

                    </Col>
                    <Col span={12} style={{paddingLeft: 16}}>
                        <Form.Item
                            name={"confirmPassword"}
                            label={"Confirm Password"}
                            rules={[{required: true, message: "can't be blank"}]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </Col>

                </Row>


            </Form>
            <ButtonV2 type={"primary"} style={{height: 40, borderRadius: 5, width: '100%'}} onClick={async () => {
                form
                    .validateFields()
                    .then(async (values) => {
                        if (values.password !== values.confirmPassword) {
                            message.error("Passwords don't match. Please ensure same password is typed in 'Password' and 'Confirm Password' fields");
                            return;
                        }
                        values.createCompany = true;
                        const {data} = await signup({data: values});
                        if (data.success) {
                            form.resetFields();
                            // message.success("Sign up Success, Now you can login using your credentials.");
                            // setView("login");
                            const user = await loginRequest(values.email, values.password, dispatch);
                            if (user) { // logged in
                                navigate("/dashboard");
                            }
                            message.success("Signup Successful");
                        } else {
                            message.error(data.message);
                        }
                    })
                    .catch((info) => {
                        console.error('Validate Failed:', info);
                    });
            }}>Sign up</ButtonV2>
            <div style={{marginTop: 16}}>
                <div style={{float: 'left'}}>
                    Already have an account ? <Link to={"#"} onClick={() => setView('login')}><strong>Sign in</strong></Link>
                </div>
            </div>
        </div>
    )
}

const ResetComponent = ({setView}) => {
    const [form] = Form.useForm();
    return (
        <div style={{width: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
            <h1>Reset Password</h1>
            <div style={{color: '#666', paddingBottom: 32}}>We will send a Reset password link to your email id, which
                will be valid for 30 minutes.
            </div>
            <Form
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    name={"email"}
                    label={"Email"}
                    rules={[{required: true, type: "email", message: "Please enter a valid Email id"}]}
                >
                    <Input/>
                </Form.Item>
            </Form>
            <ButtonV2 type={"primary"} style={{height: 40, borderRadius: 5, width: '100%'}} onClick={async () => {
                form
                    .validateFields()
                    .then(async (values) => {
                        const {email} = values;
                        const {data} = await forgotPassword({email});
                        if (data.success) {
                            form.resetFields();
                            message.success("Reset Password link sent on email.");
                            setView("login");
                        } else {
                            message.error(data.message);
                        }
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}>Send Reset Password Link</ButtonV2>
            <div style={{marginTop: 16}}>
                <div style={{float: 'left'}}>
                    <Link to={"#"} onClick={() => setView('login')}><strong>Back to Sign in</strong></Link>
                </div>
            </div>
        </div>
    )
}


const LoginPage = ({defaultView}) => {
    const puc = isPuc();
    const isZoomed = useMediaQuery({
        query: '(max-height: 700px)'
      })
    const navigate = useNavigate();

    const [view, setView] = useState('login');

    let isMobile;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    useEffect(() => {
        if (isMobile) {
            console.log("## This is mobile ##");
            navigate("/m");
        } else {
            // message.error("Web Device");
        }
    }, [isMobile, navigate]);

    useEffect(() => {
        if (defaultView) {
            setView(defaultView);
        }
    }, [defaultView]);

    useEffect(() => {
        console.log("## Login Effect ##");
        const _user = Cookies.get("invUser");
        const _pass = Cookies.get("invPass");
        if (_user && _pass) {
            navigate("/");
        }
    });

    return (
        <div
        >
            <Row style={{height: '100vh', padding: 0, margin: 0}}>
                <Col onClick={() => {
                    // window.location = "https://khatabuddy.com";
                }} span={11} style={{
                    // border: '5px solid red',
                    backgroundImage: `url(${loginBack})`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    height: '100%'
                    // cursor: 'pointer'
                }}>
                </Col>
                <Col span={13} style={{padding: 32, height: '100%'}}>
                    <Row>
                        <div style={{display:'flex', alignItems: 'center', width: '100%'}}>
                        <Col span={12}>
                            <img alt={"Logo"} src={logo} width={175}/>
                        </Col>
                        <Col span={12} style={{textAlign: 'right'}}>
                        <div>
                <Button className={"hoverGreenBackground"} style={{
                                marginLeft: 24,
                                borderRadius: 4,
                                // color: 'white',
                                border: 'none', padding: 0,
                                height: 22, fontWeight: 'bold', fontSize: 14, paddingLeft: 8, paddingRight: 8
                            }}
                                    onClick={() => {
                                        const msg = puc ? "Hello, I need some help on PUC Software." :
                                            "Hello, I need some help on KhataBuddy platform.";
                                        const url = `https://api.whatsapp.com/send/?phone=91${"8700260932"}&text=${encodeURI(msg)}`;
                                        window.open(url, '_blank');
                                    }}
                            ><CustomerServiceFilled/> Help/Support</Button>
                            </div>
                        </Col>
                        </div>
                    </Row>
                    <Divider/>
                    <div style={{padding:0}}>
                        {
                            view === "login" &&
                            <LoginComponent setView={setView}/>
                        }
                        {
                            view === "signup" &&
                            <SignupComponent setView={setView}/>
                        }
                        {
                            view === "reset" &&
                            <ResetComponent setView={setView}/>
                        }
                    </div>
                    <div style={{position: isZoomed ? 'static' : 'absolute', bottom: 0, width: '90%', textAlign: 'center', color: 'grey', fontSize: 10, border: '0px solid blue'}}>
                        <img alt={"img india"} src={imgIndia} width={175} style={{marginTop: '10px'}}/>
                        <div>
                            © 2022 KhataBuddy | All rights reserved.
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default LoginPage;
