import React, {useEffect, useState} from "react";
import {Col, Drawer, Row, Segmented} from "antd";
import {useSelector} from "react-redux";
import DrawerInvoice from "./invoice";
import DrawerEmail from "./email";
import DrawerPreview from "./preview";
import CheckableTag from "antd/es/tag/CheckableTag";
import DrawerReceiptWrite from "./receiptWrite";
import DrawerPaymentWrite from "./paymentWrite";
import InvoiceSettings from "../settings/InvoiceSettings";
import DrawerStockInOut from "./stockInOut";
import DrawerLedger from "./ledger";
import DrawerItem from "./item";
import DrawerProduct from "./ecommerceProducts";
import PayoutDrawer from "./payoutDrawer";
import PayNowDrawer from "./payNowDrawer";
import DeviceWriteDrawer from "./erp/deviceWrite";
import DeviceModelWriteDrawer from "./erp/deviceModelWrite";
import PartyWriteDrawer from "./erp/party";
import InventoryTransferDrawer from "./erp/inventoryTransfer";
import BulkSimsDrawer from "./erp/bulkSims";
import InstallationRightDrawer from "./erp/installation";
import ErpUserDrawer from "./erp/erpUser";
import {isErpWeb} from "../../utils";
import ErpDrawer from "../../erp/drawer";

const thermalTemplates = ["template6", "template7", "template8"];

const RightDrawer = () => {
    const popData = useSelector(state => (state.popData || {}));
    const template = useSelector(state => (state?.oldState?.company?.invoiceConfig?.template));
    
    const [visible, setVisible] = useState(false);
    const [placement, setPlacement] = useState("right");
    // console.log(`## popData ##`, popData);
    const [selectedTags, setSelectedTags] = useState(["original", "duplicate"]);
    const [entryType, setEntryType] = useState("Standard");
    const [payoutEntryType, setPayoutEntryType] = useState("Expense");
    const [selectedSegment, setSelectedSegment] = useState("");

    const closeDrawer = () => setVisible(false);

    const disableTags = popData.data?.type !== "invoice" || thermalTemplates.includes(template);

    let drawerTitle = popData.title || "";
    const voucherTitlesMap = {
        "invoice": "Invoice Details",
        "proforma": "Proforma Details",
        "receipt": "Payment Receipt",
        "ecommerce": "Products",
    }

    if (popData.type === "invoice") {
        drawerTitle = voucherTitlesMap[popData.data?.type] || "Voucher Details";
    }

    useEffect(() => {
        setVisible(popData.visible);
        if (popData.data?.entryType) {
            setEntryType(popData.data?.entryType);
        }
        if (popData.data?.payoutEntryType) {
            setPayoutEntryType(popData.data?.payoutEntryType);
        }
        setPlacement(popData.placement || "right");
        setSelectedSegment(popData.selectedSegment || popData.segments?.[0]);
    }, [popData]);


    const tags = [
        {title: "Original", value: "original"},
        {title: "Duplicate", value: "duplicate"},
        {title: "Triplicate", value: "triplicate"},
        {title: "Delivery Challan", value: "delivery"}
    ];

    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        setSelectedTags(nextSelectedTags);
    }

    const customToolTypes = ["previewInvoice", "receiptWrite", "paymentWrite", "deviceWrite", "inventoryTransfer"];

    return (
        <Drawer
            // footer={<div>Footer</div>}
            placement={placement}
            height={popData.height}
            size={popData.size || "large"} title={
            <>
                <Row justify={"center"} align={"middle"}>
                    <Col span={customToolTypes.includes(popData?.type) ? 12 : 24}>
                        <b style={{fontSize: 20, color: '#555'}}>{drawerTitle}</b>
                    </Col>
                    {
                        popData?.type === "previewInvoice" && !disableTags &&
                        <Col span={12}>
                            <div style={{
                                width: '100%',
                                paddingBottom: 4,
                                textAlign: 'center',
                                margin: 0,
                                fontWeight: 'bold',
                                // background: '#ddd',
                                paddingTop: 4,
                            }}>
                                {tags.map(tag => (
                                    <CheckableTag
                                        key={tag.value}
                                        checked={selectedTags.indexOf(tag.value) > -1}
                                        onChange={checked => handleChange(tag.value, checked)}
                                        style={selectedTags.indexOf(tag.value) > -1 ? {} : {border: '1px solid grey'}}
                                    >
                                        {tag.title}
                                    </CheckableTag>
                                ))}
                            </div>
                        </Col>
                    }
                    {
                        popData.type === "receiptWrite" &&
                            <Col span={12} style={{textAlign: 'right'}}>
                                <Segmented value={entryType} options={['Standard', 'Invoice-Wise']} onChange={(val) => setEntryType(val)} />
                            </Col>
                    }
                    {
                        popData.type === "paymentWrite" &&
                            <Col span={12} style={{textAlign: 'right'}}>
                                <Segmented value={payoutEntryType} options={['Expense', 'Vendor']} onChange={(val) => setPayoutEntryType(val)} />
                            </Col>
                    }
                    {
                        popData?.type === "previewInvoice" && disableTags &&
                            <Col span={12}></Col>
                    }
                    {
                        popData?.segments &&
                        <Col span={12} style={{textAlign: 'right'}}>
                            <Segmented value={selectedSegment || popData.segments[0]} options={popData.segments} onChange={setSelectedSegment} />
                        </Col>
                    }

                </Row>
            </>
        }
            width={popData.width || (popData?.type === "previewInvoice" ? 900 : undefined)}
            onClose={() => {
                setVisible(false);
            }} visible={visible}
            bodyStyle={popData.bodyStyle || {}}
        >
            {popData.type === "invoice" && <DrawerInvoice document={popData.data}/>}
            {popData.type === "email" && <DrawerEmail document={popData.data} closeDrawer={closeDrawer}/>}
            {popData.type === "previewInvoice" && <DrawerPreview documentId={popData.data.voucherId || popData.data.documentId} options={popData.data.options} selectedTags={selectedTags} disableTags={disableTags}/>}
            {popData.type === "receiptWrite" && <DrawerReceiptWrite voucher={popData.data} entryType={entryType} closeDrawer={closeDrawer} onSave={popData.onSave}/>}
            {popData.type === "documentSettings" && <InvoiceSettings />}
            {popData.type === "paymentWrite" && <DrawerPaymentWrite voucher={popData.data} entryType={payoutEntryType} closeDrawer={closeDrawer} onSave={popData.onSave}/>}
            {popData.type === "ledger" && <DrawerLedger selectedRecord={popData.data.selectedRecord} onSave={popData.data.onSave} closeDrawer={closeDrawer}/>}
            {popData.type === "item" && <DrawerItem selectedRecord={popData.data.selectedRecord} onSave={popData.data.onSave} closeDrawer={closeDrawer}/>}
            {popData.type === "stockInOut" && <DrawerStockInOut stock={popData.data} closeDrawer={closeDrawer} stockIn={popData.stockIn}/>}
            {popData.type === "ecommerce" && <DrawerProduct selectedRecord={popData.data.selectedRecord} closeDrawer={closeDrawer} onSave={popData.onSave()}/>}
            {popData.type === "payout" && <PayoutDrawer payoutData={popData.data} columns={popData.columns} closeDrawer={closeDrawer} />}
            {popData.type === "payNow" && <PayNowDrawer updateContent={visible} payData={popData.data} onRedirect = {popData.onRedirect} closeDrawer={closeDrawer}/>}
            {
                isErpWeb() && <ErpDrawer
                    popData={popData}
                    closeDrawer={closeDrawer}
                    selectedSegment={selectedSegment}
                />
            }
        </Drawer>
        
    )
    
};

export default RightDrawer;
