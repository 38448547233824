import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Popconfirm, message } from "antd";
import { createEcommerceOrder } from '../../services/api/ecommerce';
import moment from "moment";
import {CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const Products = ({ selectedRecord, visible = false, setVisible, closeDrawer }) => {
  const [orderStatus, setOrderStatus] = useState()
  const [cart, setCart] = useState([]);
  const [record, setRecordInformation] = useState();

  useEffect(()=>{
    setRecordInformation(selectedRecord)
    setOrderStatus(selectedRecord?.status);
    setCart(selectedRecord?.items);
  },[selectedRecord])


  const updateProductStatus = async(value)=>{
    try{
      if(!value){
        return;
      }
      selectedRecord.status = value

      await createEcommerceOrder(selectedRecord)

    }catch(e){
      console.error('Error in updating product status', e);
    }
  }

  const onChange = (e) => {
    setOrderStatus(e);
    updateProductStatus(e);
  };

  const products = [];

  cart.forEach((product, index)=>{
    products.push({
      no: index+1,
      qty: product.qty,
      rate: product.defaultRate,
      productName: product.name,
    })
  })


  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Item",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render:(val, totalRecord)=>{
          const { rate, qty } = totalRecord;
          const totalamount = qty * rate 
          return(
              <strong>{totalamount}</strong>
          )
      }
    },
  ];
  return (
    <div>
      <Row style={{ paddingTop: 10 }}>
        <Col span={16}>
          <i>Customer Details</i>
        </Col>
        <Col span={8}>
          <p>
            Order ID: <b>KB-{selectedRecord.hashId}</b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <strong>{selectedRecord.customerDetails.name}</strong>
          {"\n"}
          <p style={{ margin: 0 }}>{selectedRecord.customerDetails.phone}</p>
          {"\n"}
          <p>{selectedRecord.customerDetails.address}</p>
        </Col>
        <Col span={8}>
          <p>
            Date:{" "}
            <b>
              {moment(selectedRecord.createdDate).format("DD MMM YYYY hh:mm A")}
            </b>
          </p>
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={tableColumns}
            dataSource={products}
            pagination={false}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={8} push={18}>
          <h3 style={{ color: "green", margin: "right" }}>
            Total Amount: ₹{selectedRecord.amount}{" "}
          </h3>
        </Col>
      </Row>
      {orderStatus === "pending" && (
        <div>
        {/* // <Row style={{ paddingTop: 30 }} justify="start"> */}
          {/* // <Col span={24}> */}
          <Button
            type="primary"
            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            style={{ marginRight: 20, background: "#E8F5E9", border: "#b7eb8f",width:'220px' , color: 'green'}}
            onClick={(e) => {
              onChange('completed');
              message.success('Marked as Completed')
              closeDrawer()
            }}
          >
            Mark as Completed
          </Button>
          <Popconfirm
                        title="Are you sure to cancel this order ?"
                        onConfirm={async (e) => {
                            e.stopPropagation();
                            onChange('cancelled');
                            message.success('Successfully order has been cancelled');
                            closeDrawer()
                        }}
                        onCancel={(e) => {
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
          <Button
            type="primary"
            icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
            style={{marginLeft:10 , background: "#FFCCCB", border: "#b7eb8f", width:'220px', color:'red'  }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            Cancel order
          </Button>
          </Popconfirm>
          {/* // </Col> */}
        {/* // </Row> */}
        </div>
      )}
    </div>
  );
};

export default Products;
