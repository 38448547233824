import {useEffect, useState} from "react";
import {Button, Col, Form, Input, Radio, Row, Select, message} from "antd";
import _ from "lodash";
import {countries, states} from "../../models/constants";
import { fetchBankAccountWithIFSCCode,gstinValidation } from "../../services/api";
import { ButtonV2 } from "../../components/ButtonV2";


const findStateByGstin = (gstin = "") => {
    const inputStateCode = gstin.slice(0, 2);
    const vals = _.values(states["IN"]);
    for (const state of vals) {
        if (state.gstCode === inputStateCode) {
            return state.alphaCode
        }
    }
}

const DrawerLedger = ({selectedRecord, onSave, closeDrawer}) => {
    const type = selectedRecord?.type;
    const LedgerType = selectedRecord?.typeofLedger;
    const [form] = Form.useForm();
    const [selectedCountry, setSelectedCountry] = useState('IN');
    const [shipSelectedCountry, setShipSelectedCountry] = useState('IN');
    const [gstState, setGstState] = useState();
    const [shipGstState, setShipGstState] = useState();
    const [enableShipping, setEnableShipping] = useState(false);
    const [typeofLedger, setTypeofLedger] = useState(LedgerType|| "cash");
    const [ifscCode, setIFSCCode] = useState();



  const fetchBankAccount = async () => {
    if (!ifscCode) {
      return;
    }
    try {
      const code = ifscCode.trim().toUpperCase();
      const response = await fetchBankAccountWithIFSCCode(code);
      const { BANK, BRANCH } = response?.data;
      form.setFieldsValue({
        "branchName": BRANCH,
        "bankName": BANK,
      });
    } catch (e) {
      console.error("Error in Fetching Bank Details", e);
    }
  };
    if (gstState) {
        form.setFieldsValue({state: gstState});
    }
    if (shipGstState) {
        form.setFieldsValue({shippingState: shipGstState});
    }

    useEffect(() => {
        if (!selectedRecord) {
            return;
        }
        form.resetFields();
        if (!selectedRecord.type) {
            selectedRecord.type = "debtor";
        }

        if(selectedRecord?.type && ['cash', 'bank'].includes(selectedRecord.type) && !selectedRecord.typeofLedger){
          setTypeofLedger(selectedRecord.type)
        }

        if(selectedRecord?.typeofLedger || !_.isEmpty(selectedRecord?.bankDetails)){
          setTypeofLedger('bank')
        }

        if (!selectedRecord.country) {
            selectedRecord.country = "IN";
        }

        if (!enableShipping && !selectedRecord.shippingCountry) {
            selectedRecord.shippingCountry = "IN";
        }

        if(selectedRecord.bankDetails) {
            setTypeofLedger("bank");
            setIFSCCode(selectedRecord.bankDetails.ifscCode);
            console.log("## Selected Record ##", selectedRecord);
            form.setFieldsValue({
                typeOfLedger: "bank",
                bankName:selectedRecord.bankDetails.bankName,
                accountNumber:selectedRecord.bankDetails.accountNumber,
                branchName:selectedRecord.bankDetails.branchName,
                ifscCode: selectedRecord.bankDetails.ifscCode,
                extraInfo: selectedRecord.bankDetails.extraInfo,
            })
        }

        setSelectedCountry(selectedRecord.country);
        setShipSelectedCountry(selectedRecord.shippingCountry);
        setGstState(findStateByGstin(selectedRecord.gstin));
        setShipGstState(findStateByGstin(selectedRecord.shippingGstin));
        setEnableShipping(!!selectedRecord.enableShipping);
        form.setFieldsValue(selectedRecord);
    }, [selectedRecord, form]);

    return (
      <div>
        <Form
          form={form}
          layout="horizontal"
          // hideRequiredMark={true}
          labelCol={{ span: 6, style: { textAlign: "left" } }}
          wrapperCol={{ span: 18 }}
        >
          {/*<Form.Item name={"type"} label={"Type"} rules={[{required: true, message: "can't be blank"}]}>*/}
          {/*    <Radio.Group buttonStyle="solid" onChange={(e) => setType(e.target.value)}>*/}
          {/*        <Radio.Button value="debtor">Client</Radio.Button>*/}
          {/*        <Radio.Button value="creditor">Vendor</Radio.Button>*/}
          {/*        <Radio.Button value="cash">Cash-in-hand</Radio.Button>*/}
          {/*        <Radio.Button value="expense">Expense</Radio.Button>*/}
          {/*    </Radio.Group>*/}
          {/*</Form.Item>*/}
          {/*<h4 style={{fontWeight: 'bold'}}>Contact Info</h4>*/}
          {/*<Form.Item*/}
          {/*    name="name"*/}
          {/*    label="Name"*/}
          {/*    rules={[*/}
          {/*        {*/}
          {/*            required: true,*/}
          {/*        },*/}
          {/*    ]}*/}
          {/*>*/}
          {/*    <Input/>*/}
          {/*</Form.Item>*/}
          {["debtor", "creditor"].includes(type) && (
            <>
              <div
                className={"ledgerDetailsGroup"}
                style={{
                  background: "#f1f8e9",
                }}
              >
                <h4 style={{ fontWeight: "bold" }}>Contact Info</h4>
                <Form.Item
                  className={"ledgerFormItem"}
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder={"Full Name"} className={"customBorder"} />
                </Form.Item>
                {/*<Row>*/}
                {/*    <Col span={12} style={{paddingRight: 10}}>*/}
                <Form.Item
                  className={"ledgerFormItem"}
                  name={"phone"}
                  label={"Phone"}
                  
                >
                  <Input
                    placeholder={"Phone number"}
                    className={"customBorder"}
                    maxLength={20}
                  />
                </Form.Item>
                {/*</Col>*/}
                {/*<Col span={12}>*/}
                <Form.Item
                  className={"ledgerFormItem"}
                  name="email"
                  label="Email"
                  rules={[
                    {
                      // required: true,
                      type: "email",
                      message: "Invalid email",
                    },
                  ]}
                >
                  <Input
                    placeholder={"Email Address"}
                    className={"customBorder"}
                  />
                </Form.Item>

                <Form.Item
                  className={"ledgerFormItem"}
                  name={"pan"}
                  label={"PAN no."}
                >
                  <Input
                    placeholder={"10 digit PAN no. (Optional)"}
                    className={"customBorder"}
                  />
                </Form.Item>
                {/*    </Col>*/}
                {/*</Row>*/}
              </div>
              <div
                className={"ledgerDetailsGroup"}
                style={{ background: "#fff8e1" }}
              >
                <h4 style={{ fontWeight: "bold" }}>Billing Info</h4>
                <Form.Item
                  className={"ledgerFormItem"}
                  name={"address"}
                  label={"Billing Address"}
                >
                  <Input
                    placeholder={"Full Billing Address"}
                    className={"customBorder"}
                  />
                </Form.Item>
                <Row style={{border:"0px solid red"}}>
                   <Col span={18} style={{border:"0px solid yellow"}}>
                        <Form.Item
                          className={"ledgerFormItem"}
                          name={"gstin"}
                          label={"GSTIN"}
                          labelCol={{ span: 8, style: { textAlign: "left" } }}
                          wrapperCol={{ span: 15 }}
                        >
                      
                        <Input
                          placeholder={"GSTIN (applicable if registered dealer)"}
                          // maxLength={10}
        
                          className={"customBorder"}
                          maxLength={15}
                          onChange={(e) =>{
                            form.setFieldsValue({ gstin: e.target.value.toUpperCase()})
                            setGstState(findStateByGstin(e.target.value))
                          }

                          }
                          
                        />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{border:"0px solid green"}}>
                     {/* <Form.Item style={{width:"100%"}}> */}
                        <ButtonV2 type="primary" onClick={async()=>{
                        const gstinValue = form.getFieldValue("gstin");
                        const resp = await gstinValidation(gstinValue);
                        

                        if(!resp.data.success){
                          message.error('Invalid GSTIN');
                          return
                        }
                        message.success('GSTIN verified successfully');
                        form.setFieldsValue({
                            address: resp?.data?.data?.address,
                            name: resp?.data?.data?.name,
                            pan: resp?.data?.data?.pan
                        });          
                        }} style={{width:"100%"}}>Verify</ButtonV2>
                    
                     {/* </Form.Item> */}
                    </Col>
                  
                </Row>
                <Form.Item
                  className={"ledgerFormItem"}
                  name={"country"}
                  label={"Country"}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={"Select Country"}
                    className={"customBorder"}
                    options={countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    defaultValue={"IN"}
                    onChange={(c) => {
                      setSelectedCountry(c);
                      form.resetFields(["state"]);
                    }}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    showSearch={true}
                  />
                </Form.Item>
                <Form.Item
                  className={"ledgerFormItem"}
                  name="state"
                  label="State"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={"Select State"}
                    className={"customBorder"}
                    disabled={!!gstState}
                    options={_.map(states[selectedCountry], (state) => ({
                      label: state.name,
                      value: state.alphaCode,
                    }))}
                    allowClear={true}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    showSearch
                  />
                </Form.Item>
              </div>
              {/*    </Col>*/}
              {/*</Row>*/}
              {enableShipping && (
                <div
                  className={"ledgerDetailsGroup"}
                  style={{ background: "#efebe9" }}
                >
                  <h4 style={{ fontWeight: "bold" }}>Shipping Info</h4>
                  <Form.Item
                    className={"ledgerFormItem"}
                    name={"shippingAddress"}
                    label={"Shipping Address"}
                  >
                    <Input
                      placeholder={"Full Shipping Address"}
                      className={"customBorder"}
                    />
                  </Form.Item>
                  {/*<Row>*/}
                  {/*    <Col span={12} style={{paddingRight: 10}}>*/}
                  <Form.Item
                    className={"ledgerFormItem"}
                    name={"shippingGstin"}
                    label={"GSTIN"}
                  >
                    <Input
                      placeholder={"GSTIN (applicable if registered dealer)"}
                      maxLength={15}
                      className={"customBorder"}
                      onChange={(e) =>{
                        form.setFieldsValue({ shippingGstin: e.target.value.toUpperCase()})
                        setShipGstState(findStateByGstin(e.target.value))
                      }
                      }
                    />
                  </Form.Item>
                  {/*</Col>*/}
                  {/*<Col span={12}>*/}
                  <Form.Item
                    className={"ledgerFormItem"}
                    name={"shippingCountry"}
                    label={"Country"}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={"Select Country"}
                      className={"customBorder"}
                      options={countries.map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                      defaultValue={"IN"}
                      onChange={(c) => {
                        setShipSelectedCountry(c);
                        form.resetFields(["shipState"]);
                      }}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      showSearch={true}
                    />
                  </Form.Item>

                  <Form.Item
                    className={"ledgerFormItem"}
                    name="shippingState"
                    label="State"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={"Select State"}
                      className={"customBorder"}
                      disabled={!!shipGstState}
                      options={_.map(states[shipSelectedCountry], (state) => ({
                        label: state.name,
                        value: state.alphaCode,
                      }))}
                      allowClear={true}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      showSearch
                    />
                  </Form.Item>
                  {/*    </Col>*/}
                  {/*</Row>*/}
                </div>
              )}
              {type === "debtor" && (
                <div style={{ width: "100%", textAlign: "right" }}>
                  <u>
                    <Button
                      style={{ textAlign: "right" }}
                      onClick={() => setEnableShipping(!enableShipping)}
                    >
                      {enableShipping
                        ? "Remove Shipping Address"
                        : "Add Shipping Address"}
                    </Button>
                  </u>
                </div>
              )}
            </>
          )}
          {["expense", "cash"].includes(type) && (
            <>
              {!LedgerType && (
                <div>
                  <Form.Item name="typeOfLedger">
                    <Radio.Group
                      size="large"
                      defaultValue={typeofLedger}
                      disabled={!_.isEmpty(selectedRecord.name) ? true : false}
                      onChange={(e) => {
                        setTypeofLedger(e.target.value);
                      }}
                    >
                      <Radio value="cash">Cash</Radio>
                      <Radio value="bank">Bank</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <div style={{ marginTop: 24 }}>
                {typeofLedger === "cash" ? (
                  <Form.Item
                    className={"ledgerFormItem"}
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        min: 3
                      },
                    ]}
                  >
                    <Input
                      placeholder={"Full Name"}
                      className={"customBorder"}
                    />
                  </Form.Item>
                ) : (
                  <div>
                    <Form.Item
                      className={"ledgerFormItem"}
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={"Full Name"}
                        className={"customBorder"}
                      />
                    </Form.Item>
                    <Form.Item
                      className={"ledgerFormItem"}
                      name="accountNumber"
                      label="Account Number"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={"Bank Account Number"}
                        className={"customBorder"}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={16}>
                        <Form.Item
                          className={"ledgerFormItem"}
                          name="ifscCode"
                          label="IFSC Code"
                          rules={[{ required: true,
                          validator:(record, val)=>{
                                if(val.match(/^[A-Z]{4}0[A-Z0-9]{6}$/gi)){
                              return Promise.resolve({})
                                }
                            return Promise.reject('Please enter a valid IFSC Code')
                          } }]}
                        >
                          <Input
                            placeholder={"IFSC Code"}
                            className={"customBorder"}
                            onChange={(e) => setIFSCCode(e.target.value)}
                            style={{ marginLeft: 40 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Button type={"primary"} onClick={fetchBankAccount}>
                          Fetch IFSC Details
                        </Button>
                      </Col>
                    </Row>
                    <Form.Item
                      className={"ledgerFormItem"}
                      name="bankName"
                      label="Bank Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={"Bank Name"}
                        className={"customBorder"}
                      />
                    </Form.Item>
                    <Form.Item
                      className={"ledgerFormItem"}
                      name="branchName"
                      label="Branch Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={"Branch Name"}
                        className={"customBorder"}
                      />
                    </Form.Item>
                    <Form.Item
                      className={"ledgerFormItem"}
                      name="extraInfo"
                      label="Extra Info"
                    >
                      <Input.TextArea
                        rows={2}
                        type="text"
                        placeholder={"Extra Info to Display"}
                        className={"customBorder"}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
            </>
          )}
        </Form>

        <br />
        <ButtonV2
          type={"primary"}
          className={"customBorder"}
          onClick={async () => {
            form
              .validateFields()
              .then(async (values) => {
                console.log("## Vals #sss#", values);
                values.enableShipping = enableShipping;
                values.type = selectedRecord?.type || "debtor";
                if (values?.typeOfLedger === "bank" || LedgerType === "bank") {
                  values.bankDetails = _.pick(values, [
                    "bankName",
                    "accountNumber",
                    "ifscCode",
                    "branchName",
                      "extraInfo"
                  ]);
                  values = _.omit(values, [
                    "bankName",
                    "accountNumber",
                    "ifscCode",
                    "branchName",
                      "extraInfo"
                  ]);
                }
                try {
                  await onSave(values);
                  // message.success("Offer Saved Successfully");
                  form.resetFields();
                  closeDrawer();
                } catch (err) {
                  // message.error('Failed to save offer');
                  console.error("Failed to save offer", err);
                }
              })
              .catch((info) => {
                console.error("## Validate Failed ##", info);
              });
          }}
        >
          Save {selectedRecord.title || "Customer"}
        </ButtonV2>
      </div>
    );
};

export default DrawerLedger;
