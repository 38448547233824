import React, { useEffect, useState } from "react";
import {
  Divider,
  Input,
  Row,
  Col,
  Button,
  message,
  Card,
  Statistic,
} from "antd";
import { useSelector } from "react-redux";
import { copyToClipboard } from "../../utils";
import { fetchEcommerceSummary } from '../../services/api/ecommerce';
import _ from "lodash";
import { getPublicShareLink, PublicShareLinks } from '../../utils/index';

const EcommerceHome = () => {
  const state = useSelector((state) => state.oldState);
  const [storeInformation, setStoreInfo] = useState();
  const [publicLink, setPublicLink]= useState();

  async function getSummary(){
    try {
      const response = await fetchEcommerceSummary();
      if (!_.get(response.data, "success", false)) {
        throw new Error("Error in Fetching Summary");
      }

      let storeInfo =
      response?.data?.data || {};
      setStoreInfo(storeInfo)
    } catch (e) {
      console.error("Error in Fetching Summary details", e);
    }
  };

  useEffect(() => {
    getSummary();
    
  }, []);

  useEffect(()=>{
    if(state.company){
      const Link =  getPublicShareLink(PublicShareLinks.ECOMMERCE_ORDER, state?.company?.hashId);
      setPublicLink(Link)
    }
  },[state])

  const onButtonClick = () => {
    const Links = getPublicShareLink(PublicShareLinks.ECOMMERCE_ORDER, state?.company?.hashId);
    copyToClipboard(Links);
    message.success("Link Copied");
  };

  return (
    <div>
      {/* <h3>What is an Ecommerce Store?</h3> */}
      <div style={{ paddingTop: 8 }}>
        <Row justify={"left"} align={"left"}>
          <Col span={12} style={{ fontSize: 24, fontWeight: "bold" }}>
            Ecommerce Store
          </Col>
        </Row>
      </div>
      <Divider style={{ marginBottom: 18 }} />
      <div style={{ textAlign: "center" }}>
        <h2>
          <span style={{ color: "green" }}>KhataBuddy</span> is the best way for
          e-commerce companies to get more customers, increase AOV and LTV.
        </h2>
      </div>
      <div>
        <h2>
          What is an <strong>Ecommerce Store?</strong>
        </h2>
        <h3>
          E-commerce (electronic commerce) is the buying and selling of goods
          and services, or the transmitting of funds or data, over an electronic
          network, primarily the internet.
        </h3>
      </div>
      <div style={{ padding: 30 }}>
        <Row gutter={[10, 10]}>
          <Col span={16}>
            <Input
              readOnly={true}
              value={publicLink}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={() => onButtonClick()}>
              Copy Store Link
            </Button>
          </Col>
        </Row>
      </div>
      <div
        className="site-statistic-demo-card"
        style={{ paddingTop: 30, height: "100%" }}
      >
        <Row className="site-statistic-demo-card" style={{ paddingTop: 10 }}>
          <Col span={12}>
            <h2>How to use it?</h2>
            <iframe
              style={{
                width: "90%",
                height: "100%",
                minHeight: 220,
                maxHeight: 320,
              }}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/X7xstTSmrPQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col span={12} style={{ paddingTop: 40 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Store Views Count"
                    value={storeInformation?.storeViewsCount || 0}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Total Orders Count"
                    value={storeInformation?.totalOrdersCount || 0}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{paddingTop:15}}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Total Orders Value"
                    value={storeInformation?.totalOrdersValue || 0}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={"₹"}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Total Pending Orders"
                    value={storeInformation?.totalPendingOrdersCount || 0}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EcommerceHome;
