import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, DatePicker, Form, Input, message, Card } from "antd";
import { Loading } from "antd-mobile";
import { getInternalPageData, savePayOrder } from "../../services/api";
import { PRIMARY_COLOR } from "../../models/constants";
import moment from "moment";
import _ from "lodash";
import { SwitchSelect } from "../../components/switch";
import logoTi from "../../res/img/logo_ti.png";
import { Image } from "antd";

const TiSelfcareCart = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { userHash } = params;
    const [pageData, setPageData] = useState({});
    const [creatingOrder, setCreatingOrder] = useState(false);
    const [form] = Form.useForm();
    
    const plans = pageData?.plans || [];
    const plansMap = _.keyBy(plans, "planId");
    const cart = pageData?.cart?.items || [];

    let cartTotal = 0;
    cart.forEach((cartItem) => {
        cartTotal += plansMap[cartItem.planId]?.price || 0;
    });

    const theme = {
        lightBlue: '#f0f7ff',
        mediumBlue: '#e6f0ff',
        borderBlue: '#d9e8ff',
        headerBg: '#f5faff',
        cardBg: '#ffffff',
    };

    useEffect(() => {
        const fetchPageData = async () => {
            const response = await getInternalPageData(userHash);
            if (!response.data.success) {
                message.error("Invalid Customer");
                return;
            }
            setPageData(response.data.data);
        };
        fetchPageData();
    }, [userHash]);

    const getSubscriptionDetails = (deviceId, planId) => {
        const plan = plansMap[planId];
        const { subscriptions = [] } = pageData;
        const subscriptionsMap = _.keyBy(subscriptions, "DeviceID");
        const subscription = subscriptionsMap[deviceId];
        const newExpiry = moment(subscription?.ExpiryDate).add(plan.duration, plan.durationUnit).format("DD MMM YYYY");
        return {
            subscription,
            currentExpiry: moment(subscription?.ExpiryDate).format("DD MMM YYYY"),
            newExpiry,
        }
    }

    const onClickProceed = async (manualFormData) => {
        try {
            setCreatingOrder(true);
            const response = await savePayOrder({
                payOrder: {
                    type: "trakinfinity_renewal",
                    company: "61a3cd3ca1be8b3f0f7aba85",
                    pg: manualFormData ? "cash" : "razorpay",
                    orderDetails: {
                        cart,
                        customerName: pageData.customerDetails?.name,
                        customerMobile: pageData.customerDetails?.mobile,
                        userHash: userHash,
                        manualFormData,
                    }
                }
            });

            setCreatingOrder(false);
            const orderId = response.data.data.payOrder.orderId;
            if (manualFormData) {
                navigate(`/pay/${orderId}/summary`);
            } else {
                navigate(`/pay/${orderId}/gateway`);
            }
        } catch (err) {
            console.error("## Failed to create Order ##", err);
        } finally {
            setCreatingOrder(false);
        }
    }

    const cTdStyles = { border: '1px solid black', padding: 6 };
    const isAdmin = !!pageData?.isAdmin;

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
            backgroundColor: theme.lightBlue
        }}>
            {/* Header */}
            <div style={{ 
                padding: '12px 16px',
                borderBottom: `1px solid ${theme.borderBlue}`,
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
            }}>
                <Image
                                    preview={false}
                                       src={logoTi}
                                       style={{maxWidth: '100%', width: 168, padding: 8}}/>
                {/* <img 
                    src="/assets/trakinfinity-logo.png" 
                    alt="TrakInfinity" 
                    style={{ height: 40 }}
                /> */}
            </div>

            {/* Main Content - Scrollable */}
            <div style={{ 
                flex: 1,
                padding: '16px 16px 120px 16px', // Extra bottom padding for fixed buttons
                overflow: 'auto'
            }}>
                <h1 style={{ 
                    fontSize: 24,
                    fontWeight: 'bold',
                    marginBottom: 24,
                    textAlign: 'center'
                }}>GPS Renewals Checkout</h1>

                {/* Cart Items */}
                <div style={{ 
                    marginBottom: 24,
                    padding: '8px 4px'
                }}>
                    {cart.map((item, index) => {
                        const details = getSubscriptionDetails(item.deviceId, item.planId);
                        const plan = plansMap[item.planId];
                        
                        return (
                            <Card 
                                key={index}
                                style={{ 
                                    marginBottom: 24,
                                    borderRadius: 12,
                                    boxShadow: '0 3px 10px rgba(0,0,0,0.06)',
                                    border: `1px solid ${theme.borderBlue}`,
                                    backgroundColor: theme.cardBg,
                                    transition: 'all 0.2s ease',
                                    ':hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: `0 6px 16px rgba(${PRIMARY_COLOR}, 0.12)`
                                    }
                                }}
                                bodyStyle={{ padding: 0 }}
                            >
                                <div style={{ 
                                    borderBottom: `1px solid ${theme.borderBlue}`,
                                    backgroundColor: theme.headerBg,
                                    padding: '16px',
                                    borderRadius: '12px 12px 0 0'
                                }}>
                                    <div style={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <div>
                                            <div style={{ color: '#666', fontSize: 13, marginBottom: 2 }}>Device ID</div>
                                            <div style={{ 
                                                fontWeight: 'bold',
                                                fontSize: 16,
                                                color: '#1a1a1a'
                                            }}>
                                                {details.subscription?.DeviceID}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-end'
                                        }}>
                                            <div style={{ 
                                                color: '#666',
                                                fontSize: 13,
                                                marginBottom: 2 
                                            }}>
                                                Amount
                                            </div>
                                            <div style={{ 
                                                color: PRIMARY_COLOR,
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                            }}>
                                                ₹{plan.price}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ 
                                    padding: '20px',
                                    borderLeft: `4px solid ${PRIMARY_COLOR}`,
                                    backgroundColor: 'white'
                                }}>
                                    <div style={{ 
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: 16
                                    }}>
                                        <div>
                                            <div style={{ 
                                                color: '#666',
                                                fontSize: 13,
                                                marginBottom: 4
                                            }}>Vehicle Number</div>
                                            <div style={{ 
                                                fontSize: 15,
                                                color: '#262626',
                                                fontWeight: '500'
                                            }}>
                                                {details.subscription?.VehicleNumber}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <div style={{ 
                                                color: '#666',
                                                fontSize: 13,
                                                marginBottom: 4
                                            }}>Renewal Plan</div>
                                            <div style={{ 
                                                fontSize: 15,
                                                color: '#262626',
                                                fontWeight: '500'
                                            }}>
                                                {plan.name}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        gap: 24,
                                        backgroundColor: theme.mediumBlue,
                                        padding: '16px',
                                        borderRadius: 8,
                                        border: `1px solid ${theme.borderBlue}`,
                                        marginTop: 20
                                    }}>
                                        <div>
                                            <div style={{ 
                                                color: '#666',
                                                fontSize: 12,
                                                marginBottom: 2
                                            }}>Current Expiry</div>
                                            <div style={{ 
                                                color: '#d46b08',
                                                fontWeight: '500'
                                            }}>
                                                {details.currentExpiry}
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ 
                                                color: '#666',
                                                fontSize: 12,
                                                marginBottom: 2
                                            }}>New Expiry</div>
                                            <div style={{ 
                                                color: '#389e0d',
                                                fontWeight: '500'
                                            }}>
                                                {details.newExpiry}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                </div>

                {/* Total Amount */}
                <div style={{
                    backgroundColor: theme.headerBg,
                    padding: 20,
                    borderRadius: 12,
                    marginBottom: 24,
                    border: `1px solid ${theme.borderBlue}`,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                }}>
                    <div style={{ 
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <span style={{ fontSize: 16 }}>Total Amount</span>
                        <span style={{ 
                            fontSize: 24,
                            fontWeight: 'bold',
                            color: PRIMARY_COLOR
                        }}>₹{cartTotal}</span>
                    </div>
                </div>

                {/* Admin Form if needed */}
                {isAdmin && (
                    <Form
                        layout="vertical"
                        style={{ 
                            border: `1px solid ${theme.borderBlue}`,
                            borderRadius: 12,
                            padding: 20,
                            marginBottom: 24,
                            backgroundColor: 'white'
                        }}
                        form={form}
                        initialValues={{
                            rechargeDate: moment(),
                        }}
                        onFinish={async (formData) => {
                            formData.isPaymentReceived = !!formData.isPaymentReceived;
                            formData.sendSms = !!formData.sendSms;
                            formData.remarks = formData.remarks || "";
                            if (formData.remarks.length < 3) {
                                message.error("Invalid remarks");
                                return;
                            }
                            await onClickProceed(formData);
                        }}
                    >
                        <Form.Item label="Date" name="rechargeDate">
                            <DatePicker format="DD MMM YYYY" />
                        </Form.Item>
                        <Form.Item name="sendSms">
                            <SwitchSelect
                                checkedChildren="Send SMS"
                                unCheckedChildren="Dont send SMS"
                                valuesMap={{
                                    true: true,
                                    false: false,
                                }}
                                reverseMap={{
                                    true: true,
                                    false: false
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="isPaymentReceived">
                            <SwitchSelect
                                checkedChildren="Payment Received"
                                unCheckedChildren="Payment Pending"
                                valuesMap={{
                                    true: true,
                                    false: false,
                                }}
                                reverseMap={{
                                    true: true,
                                    false: false
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="remarks" style={{ width: '100%' }}>
                            <Input style={{ width: '100%' }} placeholder="Remarks"/>
                        </Form.Item>

                        <Button 
                            htmlType="submit" 
                            disabled={creatingOrder} 
                            type="primary"
                            style={{ marginBottom: 32, fontWeight: 'bold', fontSize: 16 }}
                        >
                            {creatingOrder ? <Loading/> : "Manual Order"}
                        </Button>
                    </Form>
                )}
            </div>

            {/* Fixed Bottom Buttons */}
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '16px 16px',
                backgroundColor: 'white',
                borderTop: `1px solid ${theme.borderBlue}`,
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                zIndex: 100,
                boxShadow: '0 -2px 8px rgba(0,0,0,0.05)'
            }}>
                <Button 
                    onClick={() => navigate(`/selfcare/${userHash}`)}
                    style={{ 
                        width: '100%',
                        height: 48,
                        border: `1px solid ${PRIMARY_COLOR}`,
                        color: PRIMARY_COLOR,
                        fontSize: 16,
                        fontWeight: '500'
                    }}
                >
                    ← Change Renewal Plan or Vehicles
                </Button>
                <Button 
                    disabled={creatingOrder}
                    type="primary"
                    onClick={() => onClickProceed()}
                    style={{ 
                        width: '100%',
                        height: 56,
                        fontWeight: 'bold',
                        fontSize: 18,
                        background: PRIMARY_COLOR,
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                    }}
                >
                    {creatingOrder ? <Loading/> : "Proceed to Pay →"}
                </Button>
            </div>
        </div>
    );
};

export default TiSelfcareCart; 