import * as Sentry from "@sentry/react";

export const initSentry = () => {
    Sentry.init({
        dsn: "https://fc078c2e5468de7e54f906a1bd1ac774@o1006782.ingest.us.sentry.io/4508642210742272",
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
    });
    overrideConsoleError();
};

const overrideConsoleError = () => {
    const nativeConsoleError = console.error;
    console.error = (...args) => {
        try {
            const lastArg = args[args.length - 1];
            let error = lastArg instanceof Error ? lastArg : null;

            if (!error) {
                error = new Error(args[0] || "ErrorWithNoTitleWeb");
            }

            // Create context from non-error arguments
            const context = {
                platform: "i14-web",
                additionalInfo: error ? args.slice(0, -1) : args
            };


            Sentry.withScope(scope => {
                scope.setExtras(context);
                Sentry.captureException(error);
            });

            return nativeConsoleError(...args);
        } catch (e) {
            return nativeConsoleError(...args);
        }
    };
};
